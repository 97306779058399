import { useState } from "react";
import propertyUpdate from '../../../assets/property-update-img2.png';
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import { EditPropertyApi } from "../../../services/PropertyManagement.service";
import toast from "react-hot-toast";
import { NULL } from "sass";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

type EditProps = {
    user_data: any;
    updateGet :()=>void;
  };

export const UpdateProperty = ({ user_data, updateGet }: EditProps) => {
  const [name, setName] = useState(user_data.name);
  const [phone, setPhone] = useState(user_data.contact_no);
  const [email, setEmail] = useState(user_data.email);
  const [isDisabled, setDisabled] = useState(false);
  const [isActive, setIsActive] = useState(user_data && user_data.status=="Active");
  
  function validateEmail(email:any) {
    var re = /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

  const updateData=async()=>{

    let updateData={
        name:name,
        contact_no:phone,
        email:email,
        is_active:isActive
    }

    if( !validateEmail(email)){
      return toast.error("Please enter a valid email")
  }
  
    if (user_data.name === name) {
      delete updateData.name;
  }
  if (user_data.contact_no === phone) {
      delete updateData.contact_no;
  }
  if (user_data.email === email) {
      delete updateData.email;
  }
    setDisabled(true)
    const update_response = await EditPropertyApi(user_data.id,updateData)
    if(update_response){
        setDisabled(false)
        if(update_response.status==100){
            toast.success("Successfully updated property")
            updateGet()
        }else{
            toast.error("Error updating property: " +update_response.message)
        }
    }


}

  return(
    <div>

    <div className="row">
    <div className="col-6 col-md-6 col-lg-6 d-flex justify-content-center align-items-center">
         <img className="img-fluid" src={propertyUpdate} /> 
    </div>

    <div className="col-6 col-md-6 col-lg-6">
               <div>
                    <p className='viewuser-heading'>Update Property Details.</p>
                    <p className='viewuser-heading2'>Please edit your property details to update account.</p>
                </div>


                <div className="pt-3 pb-3">
    <label className="input_label_style2">Property Name</label>
    <input value={name} onChange={(e)=>setName(e.target.value)} className=" mt-1 input_style_register" placeholder="First Name"/>
</div>

<div className="pb-3">
    <label className="input_label_style2">Property Contact Number</label>
    {/* <input value={phone} onChange={(e)=>setPhone(e.target.value)} className=" mt-1 input_style_register" placeholder="Last Name"/> */}

    <PhoneInput
      placeholder="Enter phone number"
      value={phone}
      onChange={setPhone} className=" mt-1 input_style_register"/>
</div>


<div className="pb-3">
    <label className="input_label_style2">Property Email Address</label>
    <input value={email} onChange={(e)=>setEmail(e.target.value)} className=" mt-1 input_style_register" placeholder="Last Name"/>
</div>

<div className="form-check form-switch pt-2">
                <label  className='active-txt'>Active</label>

                    <input checked={isActive} onChange={()=>setIsActive(!isActive)}  className="form-check-input active-user" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                    </div>

<div className="pt-3 d-flex justify-content-end">
    <button onClick={()=>updateGet()} className="discard-btn me-3">Discard</button>
    <SubmitButton
        isDisabled={isDisabled}
        buttonText="Save"
        onClickHandler={updateData}
        is_loading={isDisabled}
        classname="editsave-btn"
      />
</div>


      </div>


</div>
</div>
  );
}