import { useEffect } from 'react';
import propertyUpdate from '../../../assets/property-update-img.png';
import './ViewProperty.scss';
import { useParams } from 'react-router-dom';
import { GetChecklistByIdApi } from '../../../services/ChecklistManagement.service';
type EditProps = {
    user_data: any;
    openEdit :()=>void;
  };

export const ViewProperty = ({ user_data, openEdit }: EditProps) => {


    return (
        <div className="row">
        <div className="col-6 col-md-6 col-lg-6 left-img  d-flex justify-content-center align-items-center">
             <img className="img-fluid" src={propertyUpdate} /> 
        </div>
        <div className="col-6 col-md-6 col-lg-6">
            <div className='d-flex justify-content-around'>
            <div>
                <p className='viewuser-heading'>View Property Details</p>
                <p className='viewuser-heading2'>You can view your Property details.</p>
            </div>

            <div><button onClick={()=>openEdit()} className='edit-btn'>Edit</button></div> 
            </div>

         
            <div className='pt-4 property-details'>

<div>
    <p className='attribute-label'>Property Name</p>
    <p className='attribute-value'>{user_data && user_data.name}</p>
</div>



<div>
    <p className='attribute-label'>Property Contact Number</p>
    <p className='attribute-value'>{user_data && user_data.contact_no}</p>
</div>

<div>
    <p className='attribute-label'>Property Email Address</p>
    <p className='attribute-value'>{user_data && user_data.email}</p>
</div>      


<div className='d-flex  pt-4 pb-4'>


                    <div className="form-check form-switch">
                <label  className='active-txt'>Active</label>

                    <input  readOnly checked={user_data && user_data.status=="Active"} className="form-check-input active-user" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                    </div>
                </div>

</div>



        </div>
    </div>
    )

}