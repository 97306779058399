import { useState } from "react"
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import toast from "react-hot-toast";
import { contactUsApi } from "../../../services/ContactUs.service";

interface contactProps{
    closeModal:()=>void;
}
export const Contact = (closeModal:contactProps) =>{
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isDisabled,setIsDisabled] = useState(false);

    const submitContactForm=async()=>{

        if(name && email && message){
            let contactUsPayload={
                name:name,
                email:email,
                message:message
            }

            const contact_response = await contactUsApi(contactUsPayload);

            if(contact_response){
                if(contact_response.status=="success"){
                    toast.success("Thank you for contacting us. We will get back to you soon.")
                    closeModal.closeModal()
                }
            }

        }else{
            toast.error("You cannot keep fields blank")
        }
   
    }
    return(
        <div>
            <p>Do you want a demo. Please provide us with your details we will get back to you</p>
            <div>

            <div className="pt-3">
                    <label className="input_label_style">Name</label>
                    <input value={name} onChange={(e)=>setName(e.target.value)} className=" mt-1 input_style" placeholder="Full name"/>
                </div>


            <div className="pt-3">
                    <label className="input_label_style">Email address</label>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} className=" mt-1 input_style" placeholder="Email address"/>
                </div>


                <div className="pt-3">
                    <label className="input_label_style">Message</label>
                    <textarea value={message} onChange={(e)=>setMessage(e.target.value)}  style={{height:'100px'}} className='checklist-input'></textarea>
                </div>

                <SubmitButton
        isDisabled={isDisabled}
        buttonText="Save"
        onClickHandler={submitContactForm}
        is_loading={isDisabled}
        classname="login_btn"
      />
      
                <div>

                </div>
            </div>
        </div>
    )
}