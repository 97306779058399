import { BsCheck, BsChevronCompactRight } from 'react-icons/bs';
import './ViewChecklist.scss';
import checklistbtn from '../../../assets/checklist-btn.png'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { DeleteLockListApi, GetChecklistByIdApi, UpdateChecklistApi, UpdateLockListApi } from '../../../services/ChecklistManagement.service';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import toast from 'react-hot-toast';
import ReusableModal from '../../../components/Modal/Modal';
import DynamicInput from '../DynamicInput/DynamicInput';
import NotificationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { GetTemplateByIdApi } from '../../../services/TemplateManagement.service';

const ViewChecklist : React.FC=()=>{
  const valueMapping:any = {
    in_progress: 'In Progress',
    open: 'Open',
    complete: 'Complete',
    Active: 'Active',
    InActive: 'In Active' // Assuming 'In Active' is meant to be 'Inactive'
  };
  
    let { id } = useParams();
    const navigate = useNavigate()
    const [isDisabled, setDisabled] = useState(false);
    const [isChecklistDisabled, setChecklistDisabled] = useState(false);

    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const [checklistData, setChecklistData] = useState<any>();
    const [checklistData2, setChecklistData2] = useState<any>();
    const [checkedIds, setCheckedIds] = useState<any>([]);
    const [dataType, setDataType] = useState('');
    const [rowid, setRowId] = useState('');
    const [attributeName, setAttributeName] = useState('');
    const [attributeId, setAttributeId] = useState('');
    const [options, setOptions] = useState<any>([]);
    const [dataValue, setDataValue] = useState('');
    const [propertyModalShow, setPropertyModalShow] = useState(false);
    const togglePropertyModal = () => setPropertyModalShow(!propertyModalShow);
    const [additionalNote, setAdditionalNote] = useState('');

    const [itemAttributes, setItemAttributes] = useState<{ [key: string]: { [attribute: string]: string } }>({});
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isDiscard, SetDiscard] = useState(false);


    const [discardShow, setDiscardModalShow] = useState(false);
    const toggleDiscardModal = () => setDiscardModalShow(!discardShow);
    const [templateData,setTemplateData] = useState<any>();
    const [type, setTyperData] = useState(sessionStorage.getItem('user_type') || localStorage.getItem('user_type'));

    useEffect(() => {
        GetChecklistById()
    }, []);


    useEffect(() => {
      // Check if itemAttributes is updated and not empty (or any other condition you need)
      if (itemAttributes && Object.keys(itemAttributes).length > 0) {
          console.log(itemAttributes);
          submitData();
      }
  }, [itemAttributes]); //


  useEffect(() => {
    const handleBeforeUnload = (e:any) => {
        if (hasUnsavedChanges) {
            // Standard for most browsers
            e.preventDefault();
            // For some browsers, a return value is required
            e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
        }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function
    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };
}, [hasUnsavedChanges]); // Depend on hasUnsavedChanges

    
  // Initialize checkedIds based on value.is_completed
  const initialLoad = useRef(true);

  // useEffect for initial setting of checkedIds
  useEffect(() => {
      const initialCheckedIds = checklistData && checklistData.filter((item:any) => item.is_completed).map((item:any) => item.id);
      console.log(initialCheckedIds);
      setCheckedIds(initialCheckedIds);
  
      // After setting initial checkedIds, mark initial load as done
      initialLoad.current = false;
  }, [checklistData,isDiscard]);
  



  const getTemplateData = async (template_id:any) => {
    const template_data = await GetTemplateByIdApi(template_id);

    if (template_data.status === 100) {
        console.log("this is template", template_data);
        setTemplateData(template_data.data);

    }
}


    const GetChecklistById= async()=>{

          const checkist_data = await GetChecklistByIdApi(id);
          console.log(checkist_data)
          let data_array:any=[]
          if(checkist_data.status==100){
            getTemplateData(checkist_data.data.template_id)
            setSubmitDisabled(checkist_data.data.is_locked==1?true:false)
               setChecklistData(checkist_data.data.action_items.data)
               setChecklistData2(checkist_data.data)
                setAdditionalNote(checkist_data.data.additional_note)
          }
          
      }

      const handleCheckboxChange = (event:any, id:any) => {
        setHasUnsavedChanges(true)
        console.log(id)
        if (event.target.checked) {
          setCheckedIds([...checkedIds, id]);
        } else {
          setCheckedIds(checkedIds.filter((checkedId:any) => checkedId !== id));
        }
      };
      


      const attributeArray = (item_id:any) => {
        console.log("fucking ass hole")
        const additionalAttributes = itemAttributes[item_id] || {};
        let value_arr:any=[]
        Object.keys(additionalAttributes).forEach(key => {
          if(key !=='comment' && key !== 'attachments' ){
            let val:any={
              "attribute_id":key,
              "value":additionalAttributes[key],
              "attribute_option_id":""
          }

          value_arr=[...value_arr,val]
          }
  

        });

        return value_arr
      }

      const submitData = async() => {

// Use `checkedIds` here for submission
console.log("Checked IDs", checkedIds);



const valuesArray = checklistData.map((item: any) => {
  // Retrieve additional attributes for each item
  const additionalAttributes = itemAttributes[item.id] || {};
  console.log("Checked attribute", itemAttributes);
  return {
      action_item_id: item.id,
      is_complete: checkedIds.includes(item.id) ? 1 : 0,
      comment: additionalAttributes.comment || item.comment||'',
      attachments: additionalAttributes.attachments || '',
      attributes:attributeArray(item.id)
  };
});
// ... rest of your submission logic
        let data={
            checklist_id:id,
            additional_note:additionalNote,
            action_items:valuesArray
        }
        console.log("data to submit",data);
        console.log("data to submit",itemAttributes);
        setDisabled(true)
        const create_response = await UpdateChecklistApi(data)
        if(create_response){
          setHasUnsavedChanges(false)
          GetChecklistById()
            setDisabled(false)
            if(create_response.status==100){
                toast.success("Successfully updated checklist")
              
            }else{
                toast.error("Error updating checklist: "+create_response.message)
            }
        }
      };





      const LockChecklist = async() => {

        // Use `checkedIds` here for submission
        console.log("Checked locked IDs", checkedIds);
        
        

        // ... rest of your submission logic
                let data={
                    checklist_id:id,
                   is_locked:1
                }
                
                setChecklistDisabled(true)
                const create_response = await UpdateLockListApi(data,id)
                if(create_response){
                
                  GetChecklistById()
                  setChecklistDisabled(false)
                    if(create_response.status==100){
                        toast.success("Successfully Locked checklist")
                      
                    }else{
                        toast.error("Error updating checklist: "+create_response.message)
                    }
                }
              };


              const DeleteChecklist = async() => {

                // Use `checkedIds` here for submission
                console.log("Checked locked IDs", checkedIds);
                
                
        
                // ... rest of your submission logic
                        let data={
                            checklist_id:id,
                           is_locked:1
                        }
                        
                        setChecklistDisabled(true)
                        const create_response = await DeleteLockListApi(data,id)
                        if(create_response){
                        
                          GetChecklistById()
                          setChecklistDisabled(false)
                            if(create_response.status==100){
                                toast.success("Successfully Deleted checklist")
                                navigate('/checklist-management')
                              
                            }else{
                                toast.error("Error updating checklist: "+create_response.message)
                            }
                        }
                      };
        
      const addData=(type:any,data:any,id:any,name:any,attribute_id:any,options:any)=>{
          setDataType(type)
          setDataValue(data)
          setRowId(id)
          setAttributeName(name)
          setAttributeId(attribute_id)
          setOptions(options)
        togglePropertyModal()

      }

    //   const updateJson = (value: any) => {
    //     setPropertyModalShow(false)

    //     // Update itemAttributes state with the new value based on rowId
    //     setItemAttributes(prev => ({
    //         ...prev,
    //         [rowid]: { ...prev[rowid], [attributeName === 'Comment' ? 'comment' : 'attachments']: value }
    //     }));

    //     console.log(itemAttributes)
   
    // }
    const updateJson = (value: string) => {
      setPropertyModalShow(false);
  
      // Dynamically update the itemAttributes state with the new value based on rowId and attributeName
      setItemAttributes(prevAttributes => ({
          ...prevAttributes,
          [rowid]: {
              ...prevAttributes[rowid],
              [attributeId]: value // Use attributeName directly without assuming specific attributes
          }
      }));
  };
  

    const discardDetails=()=>{
      SetDiscard(!isDiscard)
      setDiscardModalShow(false)
    }

  const findAttributeValue=(id:any,attribute_array:Array<any>)=>{
    const foundItem = attribute_array.find(item => item.attribute_id === id);
    console.log(id)
    console.log(foundItem)
    return foundItem ? foundItem : "";

  }  
return(<div className='main-content  fade-in'>

<div className="d-flex justify-content-between">
        <div className="d-flex">
          <p className="breadcrumb pe-2">Home</p>
          <BsChevronCompactRight/>
                <p className="breadcrumb ps-2">Open Checklist</p>
        </div>
</div>


{/* <div>
      <button type="button" className="checklist-view-btn" > <span className='pe-2'><img src={checklistbtn} /></span>
      {checklistData2 && checklistData2.name}     </button>

 
    </div> */}



    {/* <div className='d-flex pt-3 checklist-attributes' >

        <button className='checklist-view-btn2'>{checklistData2 && checklistData2.property_name}</button>

        <button className='checklist-view-btn2'>{checklistData2 && checklistData2.status && valueMapping[checklistData2.status] }</button>

        <button className='checklist-view-btn2'>{checklistData2 && checklistData2.due_date}</button>

        <button className='checklist-view-btn2'>{checklistData2 && checklistData2.assignee_name}</button>

    </div> */}


    <div className='checklist-template-btn-bg mt-4 ps-3'>
        <button className='checklist-template-btn'>{checklistData2 && checklistData2.template_name}</button>
    </div>


    <div className='pt-4 scrollable-row'>
 {checklistData && checklistData.map((value:any)=>(   value.is_active? <div className='pb-4 mb-2 d-flex' >
 <div className='col-1 text-center'>
       <input
  className='checkbox-style'
  checked={checkedIds && checkedIds.includes(value.id)}
  type="checkbox"
  id={value.id}
  name={value.id}
  value={value.id}
  onChange={(e) => handleCheckboxChange(e, value.id)}
  disabled={isSubmitDisabled}
/>
        </div>
        { templateData && templateData.category_is_active==1 &&        <div className='col-2 action-txt4 text-left'>{value.category}</div>}
        <div className='col-3 action-txt3 text-left'>{value.action_item}</div>

{templateData && templateData.priority_is_active==1 &&        <div className='col-3 action-txt action-txt2 d-flex align-items-center justify-content-between'>{value.priority} priority <div className={`ms-2 me-5 ${value.priority=="high"?'indicator':value.priority=="medium"?'indicator2':value.priority=="low"?'indicator3':''}`}></div></div>}
{templateData && templateData.attachment_is_active==1 &&        <div className='col-2'><button  className='file' onClick={()=>addData("file",value.attachments,value.id,"Attachment","attachments",[])}>{value.attachments && value.attachments.length>0 ?value.attachments.length + ' Attachment':"Attachment"}</button></div>}
        
{ templateData && templateData.comment_is_active==1 &&       <div className='col-2' title={value.comment}><button className='comment' onClick={()=>addData("textarea2",value.comment,value.id, "Comment","comment",[])}>
  <span className='elipsis-text'>{value.comment?value.comment:"Comment"}</span> </button></div>}
        
        {/* <div className='col-2'><button className='comment' onClick={()=>addData("textarea",value.comment,value.id, "Comment","comment")}>Comment</button></div>

        <div className='col-2'><button className='comment' onClick={()=>addData("textarea",value.comment,value.id, "Comment","comment")}>Comment</button></div> */}

        {templateData && templateData.attributes.map((attributes:any)=>(
              <div className='col-2'><button className='comment' onClick={()=>addData(attributes.type,findAttributeValue(attributes.id,value.attributes),value.id, attributes.name,attributes.id,attributes.options)}> <span className='elipsis-text'>{attributes.type!=='files' && findAttributeValue(attributes.id,value.attributes)?findAttributeValue(attributes.id,value.attributes).value:attributes.type=='files' && findAttributeValue(attributes.id,value.attributes)?'1 file':attributes.name} </span> </button></div>

        ))}

      </div>:<></>
))}
    </div>


      <div className='pt-4'>
        <label className="checklist-labels">
            Additional Notes
        </label>

        <textarea value={additionalNote} onChange={(e)=>setAdditionalNote(e.target.value)} style={{height:'100px'}} className=" mt-1 checklist-input" disabled={isSubmitDisabled}></textarea>
      </div>
        
   {!isSubmitDisabled && <div className="pt-3 pb-4 d-flex justify-content-end">
    <button className="discard-btn me-3" onClick={()=>toggleDiscardModal()}>Discard</button>

{type=="SystemAdmin" &&    <SubmitButton
        isDisabled={isChecklistDisabled}
        buttonText="Delete"
        onClickHandler={DeleteChecklist}
        is_loading={isChecklistDisabled}
        classname="discard-btn me-3"
      />}

    <SubmitButton
        isDisabled={isChecklistDisabled}
        buttonText="Lock"
        onClickHandler={LockChecklist}
        is_loading={isChecklistDisabled}
        classname="discard-btn me-3"
      />
    <SubmitButton
        isDisabled={isDisabled}
        buttonText="Save"
        onClickHandler={submitData}
        is_loading={isDisabled}
        classname="editsave-btn"
      />

    </div>}


{isSubmitDisabled &&    <span className='lock-txt'>*** This checklist is locked. You cannot further update it.</span>
}


    <ReusableModal
        show={propertyModalShow}
        toggleModal={togglePropertyModal}
        title={"Add " + attributeName}
        is_title={true}
      >
     <DynamicInput data={dataValue} type={dataType} onValueChange={updateJson} name={attributeName} options={options} submit_disabled={isSubmitDisabled}/>
      </ReusableModal>

    
      <NotificationModal
        show={discardShow}
        toggleModal={toggleDiscardModal}
        title="Create User Account"
      >
       <p className='delete-confirmation'> Are you sure you want to discard all changes ?</p>

        <div className='d-flex justify-content-center'>
        <button onClick={()=>discardDetails()} className="delete-btn-confirm me-3 mt-3">Yes</button>
        <button onClick={()=>toggleDiscardModal()} className="no-btn-confirm me-3 mt-3">No</button>
        </div>
      </NotificationModal>
</div>)

}

export default ViewChecklist;

