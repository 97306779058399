

import React, { useState } from 'react';
import './PasswordInput.scss';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

type passwordProps={
    label:string,
    value:any,
    onChange:any,
}

export const PasswordInputField = ({ label, value, onChange }:passwordProps) => {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  return (
    <div className="password-field">
    <label className='password-label'>{label}</label>
    <div className="input-group">
      <input
        type={isPasswordVisible ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        placeholder='. . . . . . . . . . . . . . . . . . . . . '
        className="password-input"
        
      />
      <button
        onClick={togglePasswordVisibility}
        className="toggle-password-visibility"
        type="button"
      >
        {!isPasswordVisible ? <BsFillEyeFill/> : <BsFillEyeSlashFill/>}
      </button>
    </div>
  </div>
  );
};
