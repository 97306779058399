
import property from "../../assets/property-img.png";

type props={
    name:any;
}
export const ComingSoon = ({ name}: props) => {

return(
    <div className="d-flex justify-content-center align-items-center">
        <div>
            <p className="text-center viewuser-heading">{name} Coming Soon!!!</p>
        <img  src={property}/>
        </div>
    </div>
)
}