import axios from 'axios';
// import { base64Encoder, base64Decoder, aes_encrypt, aes_decrypt } from './encryption';


const api = axios.create({
  baseURL: "https://api-prod.thetaskboard.com/api/v1"//process.env.REACT_APP_BASE_URL,
});


export const apiCall = async (url:any, method = 'GET', data = null) => {
//   const token = localStorage.getItem('token');
//   if(data){
//     data=encrypt(data)
//   }

  const headers = {
    'Content-Type': 'application/json'//'text/plain',
    // Authorization: `Bearer ${token}`,
  };

  try {
    const response = await api.request({
      url,
      method,
      data,
      headers,
    });
    console.log("function return",response)
    return response.data

    // return decrypt(response.data);
  } catch (error) {
    // Handle error here

  }
};


export const authorizedapiCall = async (url:any, method = 'GET', data = null) => {
    const token = await sessionStorage.getItem('access_token') || localStorage.getItem('access_token');
    // if(data){
    //   data=encrypt(data)
    // }
  
    const headers = {
      'Content-Type': 'application/json',//'text/plain',
      Authorization: `Bearer ${token}`,
    };
  
    try {
      const response = await api.request({
        url,
        method,
        data,
        headers,
      });
      console.log("function return",response)
      return response.data
  
      // return decrypt(response.data);
    } catch (error:any) {
      if (error.response && error.response.status === 401) {
        // Navigate to login if a 401 Unauthorized error is caught
        window.location.href = "/login";
      }
  
    }
  };



  export const CustomAuthorizedApiCall = async (url:any, method = 'GET', data = null,token_string:any) => {
    // const token = sessionStorage.getItem('access_token');
    // if(data){
    //   data=encrypt(data)
    // }
  
    const headers = {
      'Content-Type': 'application/json',//'text/plain',
      Authorization: `${token_string}`,
    };
  
    try {
      const response = await api.request({
        url,
        method,
        data,
        headers,
      });
      console.log("function return",response)
      return response.data
  
      // return decrypt(response.data);
    } catch (error) {
      // Handle error here
  
    }
  };


  export const FileUploadauthorizedapiCall = async (url:any, method = 'GET', file_type='jsno') => {
    const token = await sessionStorage.getItem('access_token') || localStorage.getItem('access_token');
    // if(data){
    //   data=encrypt(data)
    // }
    let data=null
  
    const headers = {
      'Content-Type': file_type,//'text/plain',
      'x-amz-acl': 'public-read',
      Authorization: `Bearer ${token}`,
    };
  
    try {
      const response = await api.request({
        url,
        method,
        data,
        headers,
      });
      console.log("function return",response)
      return response.data
  
      // return decrypt(response.data);
    } catch (error:any) {
      if (error.response && error.response.status === 401) {
        // Navigate to login if a 401 Unauthorized error is caught
        window.location.href = "/login";
      }
  
    }
  };


