
import { useState } from 'react';
import createImg from '../../../assets/property-img.png';
import toast from 'react-hot-toast'
import './CreateProperty.scss';
import { CreatePropertyApi } from '../../../services/PropertyManagement.service';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

type PropertyProps = {
    updateProperty :()=>void;
  };

export const CreateProperty = ({ updateProperty }: PropertyProps) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState<any>();
    const [email, setEmail] = useState('');
    const [isDisabled, setDisabled] = useState(false);


    function validateEmail(email:any) {
        var re = /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const createPropertyData=async()=>{

        let createData={
            name:name,
            contact_no:phone,
            email:email,
        }

        if( !validateEmail(email)){
            return toast.error("Please enter a valid email")
        }
        setDisabled(true)
        const create_response = await CreatePropertyApi(createData)
        if(create_response){
            setDisabled(false)
            if(create_response.status==100){
                toast.success("Successfully created property")
                updateProperty()
            }else{
                toast.error("Error creating property: "+create_response.message)
            }
        }


    }
    
    return(
        <div className="row">
        <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center ">
             <img className="img-fluid" src={createImg} /> 
        </div>

        <div className="col-12 col-md-6 col-lg-6">

        <div className='pb-3'>
                    <p className='viewuser-heading'>Create Property</p>
                    <p className='viewuser-heading2'>Please enter relevant details to create property.</p>
                </div>


                <div  className="pb-4">
    <label className="input_label_style2">Property Name</label>
    <input value={name} onChange={(e)=>setName(e.target.value)} className=" mt-1 input_style_register" placeholder="Enter the Property Name"/>
</div>

<div  className="pb-4">
    <label className="input_label_style2">Property Contact Number</label>
    {/* <input value={phone} onChange={(e)=>setPhone(e.target.value)} className=" mt-1 input_style_register" placeholder="Enter the Property Contact Number"/> */}
    <PhoneInput
      placeholder="Enter phone number"
      value={phone}
      defaultCountry="US"
      onChange={setPhone} className=" mt-1 input_style_register"/>
  
</div>


<div  className="pb-4">
    <label className="input_label_style2">Property Email Address</label>
    <input value={email} onChange={(e)=>setEmail(e.target.value)} className=" mt-1 input_style_register" placeholder="Enter the Property Email Address"/>
</div>


<div className="pt-3 d-flex justify-content-end">
   
    <SubmitButton
        isDisabled={isDisabled}
        buttonText="Save"
        onClickHandler={createPropertyData}
        is_loading={isDisabled}
        classname="propertysave-btn"
      />
</div>


        </div>

        </div>
    );


}