import React, { useEffect, useState } from "react";
import { ComingSoon } from "../../components/ComingSoon/ComingSoon";


const Settings : React.FC=()=>{



    return(
        <div>

          <ComingSoon name="Settings"/>


        </div>
        );

}

export default Settings;
