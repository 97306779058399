import React from 'react';
import { Document, Page, Text, View, StyleSheet, Link, Image  } from '@react-pdf/renderer';
import checklistbtn from '../../../assets/checklist-btn.png'

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
  },
  section: {
    marginLeft: 10,
    marginTop: 10,
    marginRight: 10,
    marginBottom:0,
    paddingBottom:0,
    paddingLeft: 10,
    paddingRight: 10,
    // flexGrow: 1,
    width:'150px',
    height: '80px',
    borderRadius: '5px'
  },

  section2: {
    marginTop:0,
    paddingTop:0,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    // flexGrow: 1,
    borderRadius: '5px',
    paddingBottom:10
  },


  section3: {
    marginTop:0,
    paddingTop:10,
    // flexGrow: 1,
    borderRadius: '5px',
    paddingBottom:10
  },

  section4: {
    marginTop:0,
    paddingTop:10,
    // flexGrow: 1,
    borderRadius: '5px',
    paddingBottom:10
  },
  rowsection: {
    marginTop:0,
    paddingTop:0,
flexDirection:'row',
  },

  rowsection2: {
    marginTop:0,
    paddingTop:10,
flexDirection:'row',
  },


  colsection: {
    width:'30%',
      },
      colsection2: {
        width:'70%',
          },

          flexdivision: {
          flexGrow:1
              },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  title: {
    fontSize: 12,
    textAlign: 'center',
    backgroundColor: '#0300AC',
    color: '#fff',
    padding: 8,

  },

  labels: {
    fontSize: 11,
    backgroundColor: '#F6F6F6',
    padding: 7,
    color: '#5E5873',
  },

  labels2: {
    fontSize: 11,
    textAlign: 'center',
    color: '#818183',
  },

  high: {
    fontSize: 11,
    textAlign: 'center',
    backgroundColor: 'red',
color: 'red',

  },

  medium: {
    fontSize: 11,
    textAlign: 'center',
    backgroundColor: '#FFEB80',
color: '#FFEB80',

  },

  low: {
    fontSize: 11,
    textAlign: 'center',
    backgroundColor: '#28C76F',
color: '#28C76F',

  },

  complete: {
    fontSize: 11,
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#0AA949',
  },

  incomplete: {
    fontSize: 11,
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#6E6B7B',
    width:100,
    padding:7
  },


  section3Title: {
    fontSize: 11,
    backgroundColor: '#ABFFD038',
    color: '#28C76F',
padding:10,
fontWeight:500,
  },


  section3Value: {
    fontSize: 11,
    backgroundColor: '#ABFFD038',
    color: '#28C76F',
padding:10,
  },


  section4Title: {
    fontSize: 11,
    backgroundColor: '#3050F617',
    color: '#5F668B',
padding:10,
fontWeight:500,
  },


  section4Value: {
    fontSize: 11,
    backgroundColor: '#3050F617',
    color: '#5F668B',
padding:10,
  },


  dynamicSection:{
    marginTop:0,
    paddingTop:10,
    // flexGrow: 1,
    borderRadius: '5px',
    paddingBottom:10
  },


  dynamicSectionTitle: {
    fontSize: 11,
    backgroundColor: '#F0FFFF',
    color: '#0096FF',
padding:10,
fontWeight:500,
  },


  dynamicSectionValue: {
    fontSize: 11,
    backgroundColor: '#F0FFFF',
    color: '#0096FF',
padding:10,
  },

  status: {
    fontSize: 11,
    backgroundColor: '#FFEB80',
    padding: 10,
    width:'100px',
    color: '#fff',
  },

img:{

width:'30px',
height:'23',
paddingLeft:10,
paddingTop:5
},

img_box:{


height:"35px",
  // flexGrow:1,
  flexDirection:'row',
  backgroundColor: '#0300AC',
  },
  contentContainer: {
    marginTop:0,
    paddingTop:8,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  actionItem: {
    marginBottom: 10,
  },
  actionHeader: {
    fontSize: 18,
    marginBottom: 4,
  },
  actionContent: {
    fontSize: 14,
    marginBottom: 4,
  },
  actionPriority: {
    fontSize: 12,
    marginBottom: 4,
    color: '#ff0000',
  },
  attachment: {
    textDecoration: 'underline',
    fontSize: 11,
    backgroundColor: '#3050F617',
    color: '#5F668B',
padding:10,
  }
});

const valueMapping:any = {
  in_progress: 'In Progress',
  open: 'Open',
  complete: 'Complete',
  Active: 'Active',
  InActive: 'In Active' // Assuming 'In Active' is meant to be 'Inactive'
};

const valueMapping2:any = {
  high: 'High Priority',
  low: 'Low Priority',
  medium: 'Medium Priority',
};


type print={
    data:any;
    data2:any;
}
// Create Document Component
const PrintDocument = ({ data, data2 }:print) => {
  const displayValue = valueMapping[data] || data;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.img_box}>
            
            <Image style={styles.img} src={checklistbtn}/>
            
            <Text style={styles.title}>{data && data.template_name}</Text>
            
            
            </View>
        </View>

        <View style={styles.section2}>
            <View style={styles.rowsection}>
                <View style={styles.colsection}>
                        <Text style={styles.labels}>Property Name</Text>
                </View>
                <View style={styles.colsection2}>
                        <Text style={styles.labels}>: {data && data.property_name}</Text>
                </View>
            </View>
         
            <View style={styles.rowsection}>
                <View style={styles.colsection}>
                        <Text style={styles.labels}>Submitted By</Text>
                </View>
                <View style={styles.colsection2}>
                        <Text style={styles.labels}>: {(data && data.submitted_by)?data.submitted_by:"N/A"}</Text>
                </View>
            </View>


            <View style={styles.rowsection}>
                <View style={styles.colsection}>
                        <Text style={styles.labels}>Submitted On</Text>
                </View>
                <View style={styles.colsection2}>
                        <Text style={styles.labels}>:{(data && data.submitted_on)?data.submitted_on:" N/A"}</Text>
                </View>
            </View>


            <View style={styles.rowsection}>
                <View style={styles.colsection}>
                        <Text style={styles.labels}>Created By</Text>
                </View>
                <View style={styles.colsection2}>
                        <Text style={styles.labels}>: {data && data.created_by}</Text>
                </View>
            </View>

            
            <View style={styles.rowsection}>
                <View style={styles.colsection}>
                        <Text style={styles.labels}>Created On</Text>
                </View>
                <View style={styles.colsection2}>
                        <Text style={styles.labels}>:  {data && data.created_on}</Text>
                </View>
            </View>


            <View style={styles.rowsection}>
                <View style={styles.colsection}>
                        <Text style={styles.labels}>Current Status</Text>
                </View>
                <View style={styles.colsection2}>
                        <Text style={styles.labels}>:  <Text style={styles.status}>{data && valueMapping[data.status]}</Text></Text>
                </View>
            </View>

        </View>


        <View style={styles.contentContainer}>
{data2 && data2.map((item:any, index:any) => (    <View>
        <View style={styles.rowsection2}>
        <View style={styles.flexdivision}>
                       {item.is_completed==1 && <Text  style={styles.complete}>Complete</Text>}
                       {item.is_completed==0 && <Text  style={styles.incomplete}>Incomplete</Text>}

                </View>

                <View style={styles.flexdivision}>
                        <Text style={styles.labels2}>Action Item : {item.action_item}</Text>
                </View>

                <View style={styles.flexdivision}>
                        <Text style={styles.labels2}>{valueMapping2[item.priority]} {item.priority=='high' &&<Text style={styles.high}>...</Text>} {item.priority=='medium' &&<Text style={styles.medium}>...</Text>} {item.priority=='low' &&<Text style={styles.low}>...</Text>}</Text>
                </View>


                </View>

  

            {item.attributes && item.attributes.map((value:any,index2:any)=>(
                   <View style={styles.dynamicSection}>
                   <Text style={styles.dynamicSectionTitle}>{value.name}</Text>
   
                {!value.value.includes('taskBoard/') &&  <Text style={styles.dynamicSectionTitle}>{value.value}</Text>}

                {/* {value.value.includes('taskBoard/') && value.value && JSON.parse(value.value.replace(/'/g, '"')).map((img:any)=>(
                <Link style={styles.dynamicSectionTitle} src={'https://taskboard-images.s3.amazonaws.com/'+img.source_url}>   {img.source_url}</Link>

                ))} */}

{value.value.includes('taskBoard/') && 
                <Link style={styles.dynamicSectionTitle} src={'https://taskboard-images.s3.amazonaws.com/'+value.value}>   {value.value}</Link>

                }
               </View> 
            ))}

<View style={styles.section3}>
                <Text style={styles.section3Title}>Comment</Text>

                <Text style={styles.section3Title}>{item.comment?item.comment:'No Comments Added'}</Text>
            </View> 
<View style={styles.section4}>
                <Text style={styles.section4Title}>Attachment</Text>

                {item.attachments && item.attachments.map((img:any)=>(
                <Link style={styles.attachment} src={'https://spemai-chat-agent.s3.amazonaws.com/'+img.source_url}>   {img.source_url}</Link>

                ))}

                {item.attachments.length==0 && <Text style={styles.section4Title}>   No Attachments</Text>}
            </View> 
            </View>      
            ))}
          {/* Map over your data here to create action items */}
    
        </View>
      </Page>
    </Document>
  );
};

export default PrintDocument;
