import { authorizedapiCall } from "../utils/api_util.service";

export const GetChecklistApi = async (params:any) => {
    try {
      const data = await authorizedapiCall('checklist/?'+params);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }

  export const GetChecklistByIdApi = async (params:any) => {
    try {
      const data = await authorizedapiCall('checklist/'+params+'/');
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }


  export const CreateChecklistApi = async (payload:any) => {
    try {
      const data = await authorizedapiCall('checklist/','POST',payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }


  export const UpdateChecklistApi = async (payload:any) => {
    try {
      const data = await authorizedapiCall('checklist/action-item/','POST',payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }

  export const UpdateLockListApi = async (payload:any,id:any) => {
    try {
      const data = await authorizedapiCall('checklist/'+id+'/','PUT',payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }


  export const DeleteLockListApi = async (payload:any,id:any) => {
    try {
      const data = await authorizedapiCall('checklist/'+id+'/','DELETE',payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }
  
  export const getChecklistExcelApi = async (payload:any) => {
    try {
      const data = await authorizedapiCall('checklist-pdf/'+payload+'/');
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }



