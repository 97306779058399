
import { Accordion } from 'react-bootstrap';
import './Support.scss';
export const Support = ()=>{

    return(
        <div>

<div className="row">
        <div className="col-12 col-md-8 col-lg-8 d-flex">
          <p className="breadcrumb pe-2">Support</p>
       
        </div>
</div>
            <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header className='table-header'>User Guide</Accordion.Header>
        <Accordion.Body>
        <iframe
                      
                      className="support-doc"
                      src={'https://taskboard-support-docs.s3.us-east-2.amazonaws.com/01.+Basic+User+Guide.pdf'}
                     
                      title="Support doc"
                    />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header className='table-header'>User Setup</Accordion.Header>
        <Accordion.Body>
        <iframe
                      
                      className="support-doc"
                      src={'https://taskboard-support-docs.s3.us-east-2.amazonaws.com/02.+User+set-up.pdf'}
                     
                      title="Support doc"
                    />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header className='table-header'>Property Setup</Accordion.Header>
        <Accordion.Body>
        <iframe
                      
                      className="support-doc"
                      src={'https://taskboard-support-docs.s3.us-east-2.amazonaws.com/03.+Property+set-up.pdf'}
                     
                      title="Support doc"
                    />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header className='table-header'>Template Setup</Accordion.Header>
        <Accordion.Body>
        <iframe
                      
                      className="support-doc"
                      src={'https://taskboard-support-docs.s3.us-east-2.amazonaws.com/04.+Template+set-up.pdf'}
                     
                      title="Support doc"
                    />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header className='table-header'>Checklist Setup</Accordion.Header>
        <Accordion.Body>
        <iframe
                      
                      className="support-doc"
                      src={'https://taskboard-support-docs.s3.us-east-2.amazonaws.com/05.+Checklist+set-up.pdf'}
                     
                      title="Support doc"
                    />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        </div>
    )
}