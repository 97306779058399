import "./PrintChecklist.scss";
import checklistbtn from '../../../assets/checklist-btn.png'
import { useNavigate, useParams } from "react-router-dom";
import { GetChecklistByIdApi, getChecklistExcelApi } from "../../../services/ChecklistManagement.service";
import { useEffect, useState } from "react";
import { PDFDownloadLink, PDFViewer, pdf } from '@react-pdf/renderer';
import PrintDocument from "./PrintDocument";
import exportToExcel from "../../../utils/excel_export";

const valueMapping2:any = {
    in_progress: 'In Progress',
    open: 'Open',
    complete: 'Complete',
    Active: 'Active',
    InActive: 'In Active' 
  };
  
const PrintChecklist:React.FC =()=>{
    const navigate = useNavigate()
    const generatePdfDocument = async (document:any, fileName:any) => {
        const blob = await pdf(document).toBlob();
        const url = URL.createObjectURL(blob);
        const printWindow = window.open(url);
        printWindow?.print();
      };
      

    const handlePrint = () => {
        const document = <PrintDocument data={checklistData} data2={checklistData2} />; // Your document goes here
        generatePdfDocument(document, 'report.pdf');
      };

      const handleExport = (data2:any) => {
        // Ensure data2 is an array before exporting
        if (Array.isArray(data2)) {
          exportToExcel(data2, 'exported_data');
        } else {
          console.error('data2 is not an array');
        }
      };


    let { id } = useParams();
    const [checklistData, setChecklistData] = useState<any>();
    const [checklistData2, setChecklistData2] = useState<any>([]);
    const [checklistExcel, setchecklistExcel] = useState<any>([]);
    useEffect(() => {
        GetChecklistById()
        GetExcelChecklistById()
    }, []);
    
    const GetChecklistById= async()=>{

        const checkist_data = await GetChecklistByIdApi(id);
        console.log(checkist_data)
        let data_array:any=[]
        if(checkist_data.status==100){
         
      
              setChecklistData(checkist_data.data)
              setChecklistData2(checkist_data.data.action_items.data)
            //  setChecklistData2(checkist_data.data)
            //   setAdditionalNote(checkist_data.data.additional_note)
        }
        
    }


    const GetExcelChecklistById= async()=>{

        const checkist_data = await getChecklistExcelApi(id);
        console.log(checkist_data)
        let data_array:any=[]
        if(checkist_data.status==100){
         
      
            setchecklistExcel(checkist_data.data)
              
            //  setChecklistData2(checkist_data.data)
            //   setAdditionalNote(checkist_data.data.additional_note)
        }
        
    }

return(<div>

<div>
<div>
      <button type="button" className="checklist-view-btn mb-2" > <span className='pe-2'><img src={checklistbtn} /></span>
      {checklistData && checklistData.template_name}      </button>

 
    </div>
<div className="grey-box">

    <div className="row">
        <div className="col-lg-2 col-5 grey-labels">
        Property Name
        </div>
        <div className="col-lg-1 col-1">
            :
        </div>
        <div className="col-lg-3 col-6 grey-values">
        {checklistData && checklistData.property_name} 
        </div>
    </div>


    <div className="row">
        <div className="col-2 grey-labels">
        Submitted By
        </div>
        <div className="col-1">
            :
        </div>
        <div className="col-3 grey-values">
        {(checklistData && checklistData.submitted_by)? checklistData.submitted_by:"N/A"} 
        </div>
    </div>

    <div className="row">
        <div className="col-2 grey-labels">
        Submitted On
        </div>
        <div className="col-1">
            :
        </div>
        <div className="col-3 grey-values">
        {(checklistData && checklistData.submitted_on)?checklistData.submitted_on:"N/A"} 
        </div>
    </div>

    <div className="row">
        <div className="col-lg-2 col-5  grey-labels">
        Created By
        </div>
        <div className="col-lg-1 col-1">
            :
        </div>
        <div className="col-lg-3 col-6 grey-values">
        {checklistData && checklistData.created_by} 
        </div>
    </div>

    <div className="row">
        <div className="col-lg-2 col-5  grey-labels">
        Created On
        </div>
        <div className="col-lg-1 col-1">
            :
        </div>
        <div className="col-lg-3 col-6 grey-values">
        {checklistData && checklistData.created_on} 
        </div>
    </div>

    <div className="row">
        <div className="col-lg-2 col-5 grey-labels">
        Current Status
        </div>
        <div className="col-lg-1 col-1">
            :
        </div>
        <div className="col-lg-3 col-6 ">
            <span className="status-label">   {checklistData && valueMapping2[checklistData.status]} </span>
        
        </div>
    </div>

</div>



{checklistData2 && checklistData2.map((value:any)=>(
    <>
<div className="pt-3 pb-3 d-flex justify-content-between">

{value.is_completed==1 &&  <div className="complete-status-btn">
        Complete
    </div>}

    {value.is_completed ==0 &&    <div className="incomplete-status-btn">
        Incomplete
    </div>}

    <div className="action-item">
        Action Item : {value.action_item}
    </div>

    <div>
    <div className='action-txt action-txt2 d-flex align-items-center justify-content-between'>{value.priority} priority <div className={`ms-2 me-5 ${value.priority=="high"?'indicator':value.priority=="medium"?'indicator2':value.priority=="low"?'indicator3':''}`}></div></div>

    </div>
</div>

{value.attributes && value.attributes.map((attributes:any)=>(
    <div className="attribute-box mb-3">

    <p className="attribute-header">{attributes.name}</p>

{!attributes.value.includes('taskBoard/') &&    <p className="attribute-value">
   {attributes.value ?attributes.value:'No Value'}
   
    </p>}


    {attributes.value.includes('taskBoard/') &&
    <a className="attribute-value" target="blank" href={'https://taskboard-images.s3.amazonaws.com/'+attributes.value} download={true}>{attributes.value}</a>}

</div>
))}

<div className="comment-box mb-3">

    <p className="action-header">Comment</p>

    <p className="action-value">
   {value.comment?value.comment:'No Comments Added'}
    </p>
</div>


<div className="attachment-box">
<p className="attachment-header">Attachment</p>
<p className="attachment-value" style={{textDecoration:'None'}}>{value.attachments.length==0 && 'No attachments'}</p>
{value.attachments && value.attachments.map((image:any)=>(
    <a className="attachment-value" target="blank" href={'https://taskboard-images.s3.amazonaws.com/'+image.source_url} download={true}>{image.source_url}</a>
))}

</div>
</>
))}

</div>

<div className="pt-3 pb-4 d-flex justify-content-end">
    <button onClick={()=>navigate('/checklist-management')} className="discard-btn me-3">Cancel</button>
    <button onClick={()=>handlePrint()} className="editsave-btn me-3">Print</button>
    <button onClick={()=>handleExport(checklistExcel)} className="editsave-btn me-3">Export Excel</button>

    </div>



    {/* <PDFDownloadLink document={<PrintDocument data={data} />} fileName="checklist.pdf">
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download now!'
        }
      </PDFDownloadLink> */}

{/* <PDFViewer width={1000} height={600}>
<PrintDocument data={checklistData} data2={checklistData2} />
      </PDFViewer> */}
</div>);
}

export default PrintChecklist;