import { BsChevronCompactRight } from 'react-icons/bs';
import './PropertyManagement.scss';
import { useEffect, useState } from 'react';
import ReusableModal from '../../components/Modal/Modal';
import propertybtn from '../../assets/property-btn.png';
import { GetAllPropertyApi } from '../../services/PropertyManagement.service';
import { Table } from '../../components/Table/Table';
import { CreateProperty } from './CreateProperty/CreateProperty';
import { ViewProperty } from './ViewProperty/ViewProperty';
import { UpdateProperty } from './UpdateProperty/UpdateProperty';
import { jsonToUrlParams } from '../../utils/json_to_params_util.service';
import Pagination from '../../components/Pagination/Pagination';
import resetbtn from "../../assets/load.png";
import applybtn from "../../assets/apply-btn.png"
import { Table2 } from '../../components/Table2/Table';

const PropertyManagement : React.FC=()=>{
    var tablePageIndex = 1
    const [currentPage, setCurrentPage] = useState(tablePageIndex);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [dataCount, setDataCount] = useState<number>(0);

    const headers = ['Property Name', 'Email', 'Contact Number' ,  'Status'];

    const keys = ['name', 'email','contact_no','status'];

    const [search, setSearchData] = useState('');
    const [propertyModalShow, setPropertyModalShow] = useState(false);
    const togglePropertyModal = () => setPropertyModalShow(!propertyModalShow);
    const [propertyData, setPropertyData] = useState([]);

    const [propertyViewModalShow, setPropertyViewModalShow] = useState(false);
    const togglePropertyViewModal = () => setPropertyViewModalShow(!propertyViewModalShow);

    const [propertyEditModalShow, setPropertyEditModalShow] = useState(false);
    const togglePropertyEditModal = () => setPropertyEditModalShow(!propertyEditModalShow);
    
    const [viewData, setViewData] = useState<any>();

    const [searchName, setSearchName] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [searchContact, setSearchContact] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [isReset, SetisReset] = useState(false);

    useEffect(() => {
        GetProperties();
    }, [search]);
    
    useEffect(() => {
      GetProperties();
    }, [isReset]);
    const GetProperties= async()=>{
      let params = {
        page: tablePageIndex,
        limit: itemsPerPage,
        name:searchName,
        contact_no:searchContact,
        email:searchEmail,
        is_active:searchStatus
    };
        
        const property_data = await GetAllPropertyApi(jsonToUrlParams(params));
        console.log(property_data)
        let data_array:any=[]
        if(property_data.status==100){
            setDataCount(property_data.count)
            for(let i=0;i<property_data.data.length;i++){
                console.log("vadad")

                let data_json=  {
                    "id": property_data.data[i].id,
                    "name": property_data.data[i].name,
                    "contact_no":property_data.data[i].contact_no,
                    "email": property_data.data[i].email,
                    "status":property_data.data[i].is_active?"Active":"InActive",
                    "created_by_id": 3,
                    "created_on": "2023-11-10 06:33:06"
                }
    
                data_array= [data_json,...data_array]

            }

            setPropertyData(data_array)
            
        }
        
    }

    const handleActionClick = (rowData: { [key: string]: any }) => {
        // handle the click event
        console.log('Button clicked for:', rowData);

        setViewData(rowData)
        togglePropertyViewModal()

      };
    const renderActionButton = (row: { [key: string]: any }) => {
    
        return <button className='view-btn' onClick={() => handleActionClick(row)}>View</button>;
    
    };

    const closeCreateProperty=()=>{
        GetProperties()
        setPropertyModalShow(false)
    }
    const closeEditProperty=()=>{
        GetProperties()
        setPropertyEditModalShow(false)
    }

    const OpenEditProperty=()=>{
        setPropertyViewModalShow(false)
        setPropertyEditModalShow(true)
    }

    const handlePageChange = async (pageNumber: any) => {
        await setCurrentPage(pageNumber);
        tablePageIndex = pageNumber
        GetProperties()
      };


      const SearchUsingFilters=()=>{
        GetProperties()
      }
      
const resetBtn=()=>{
  setSearchEmail('')
  setSearchName('')
  setSearchContact('')
  setSearchStatus('')
  SetisReset(!isReset)

}

    return(
        <div className='main-content  fade-in'>
            <div className="d-flex">
                <p className="breadcrumb pe-2">Management</p>
                <BsChevronCompactRight/>
                <p className="breadcrumb ps-2">Property Management</p>
            </div>

            <div className='pb-4 pt-4  d-flex justify-content-end'>
                {/* <div>
                    <input value={search} onChange={(e)=>setSearchData(e.target.value)} className='search-input' placeholder='Search Here' />
                </div> */}

                <div>
      <button type="button" className="property-modal-btn" onClick={togglePropertyModal}> <span className='pe-2'><img src={propertybtn} /></span>
Create Property    </button>

 
    </div>


            </div>



            <div className="filter-box mt-3">
        <div className="d-flex justify-content-between pb-2">
          <div className="row w-100">
            {/* {favourites.map((value)=>(
              <div className="col-2 me-lg-0 me-md-0 me-5"><span className="recently-viewed">{value} <BsX className="recent-cross" /></span></div>
            ))} */}

          </div>

          <div>
          <div className="d-flex">
      <button onClick={()=>SearchUsingFilters()} type="button" className="apply-btn me-2 " > <span className='pe-2'><img src={applybtn} /></span>
Apply     </button>

<span onClick={()=>resetBtn()} style={{cursor:'pointer'}}><img width={35} className="img-fluid" src={resetbtn}/></span>

        </div>
            </div>    
        </div>


        <div className="row filter-margin">
          <div className="col-6 col-md-3 col-lg-3">
              <label className="filter-label">Property Name</label>
              <input value={searchName} onChange={(e)=>setSearchName(e.target.value)} className="mt-1 filter-select" placeholder="Enter name" />

          </div>

            {/* user searchable checklist */}
          <div className="col-6 col-md-3 col-lg-3">
              <label className="filter-label">Contact No</label>
              <input value={searchContact} onChange={(e)=>setSearchContact(e.target.value)} className="mt-1 filter-select" placeholder="Enter Contact no" />

          </div>

          {/* property searchable checklist */}
          <div className="col-6 col-md-3 col-lg-3">
              <label  className="filter-label">Email</label>
              <input value={searchEmail} onChange={(e)=>setSearchEmail(e.target.value)} className="mt-1 filter-select" placeholder="Enter Email" />

          </div>


          <div className="col-6 col-md-3 col-lg-3">
              <label  className="filter-label">State</label>
              <select value={searchStatus} onChange={(e)=>setSearchStatus(e.target.value)} className=" mt-1 filter-select">
              <option value="">Select status</option>
                <option value="1">Active</option>
                <option value="0">In Active</option>
     
              </select>
          </div>
        </div>
      </div>
            <Table2 headers={headers} data={propertyData}  keys={keys} isActionButton={true} navigation='ssf' openModal={(e) => handleActionClick(e)}/>
            <Pagination itemsPerPage={itemsPerPage} count={dataCount} currentPage={currentPage} onPageChange={handlePageChange} />

            <ReusableModal
        show={propertyModalShow}
        toggleModal={togglePropertyModal}
        title="Create User Account"
      >
        <CreateProperty updateProperty={closeCreateProperty}/>
      </ReusableModal>

            <ReusableModal
        show={propertyViewModalShow}
        toggleModal={togglePropertyViewModal}
        title="Create User Account"
      >
        <ViewProperty user_data={viewData} openEdit={OpenEditProperty}/>
      </ReusableModal>


      <ReusableModal
        show={propertyEditModalShow}
        toggleModal={togglePropertyEditModal}
        title="Create User Account"
      >
        <UpdateProperty user_data={viewData} updateGet={closeEditProperty}/>
      </ReusableModal>


        </div>
    );

}

export default PropertyManagement;
