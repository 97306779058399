import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import "./ConfirmationModal.scss";
interface ReusableModalProps {
  show: boolean;
  toggleModal: () => void; // assuming toggleModal is a function that takes no arguments
  title: string;
  is_title?:boolean;
  children: React.ReactNode; // This type is for anything that can be rendered: numbers, strings, elements or an array (or fragment) containing these types.
}
const NotificationModal: React.FC<ReusableModalProps> = ({ show, toggleModal, title, children ,is_title }) => {
  return (
    <Modal show={show} onHide={toggleModal} size="sm">
      <Modal.Header closeButton>
       {is_title && <Modal.Title className='dynamic-heading'>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>

    </Modal>
  );
};

export default NotificationModal;
