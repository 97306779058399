import "./Table.scss";

import React from 'react';
import PropTypes from 'prop-types';
import StatusBadge from "../StatusBadge/StatusBadge";

type TableProps = {
    headers: string[];
    data: Array<{ [key: string]: any }>;
    renderButton?: (row: { [key: string]: any }) => React.ReactNode;
    keys:string[];
    isActionButton: Boolean;
    isProgressBar?:Boolean;
    renderProgress?:(e:any)=>React.ReactNode;
    navigation?:string;
  };
  
  
  
// Define the Table component
export const Table: React.FC<TableProps> = ({ headers, data, renderButton, keys ,isActionButton, isProgressBar, renderProgress,navigation}) => {
    // const keys = Object.keys(data[0] || {});
    console.log(data)
  return (
<div className="table-responsive">
    <table  style={{width:"100%"}}>
      <thead className="table-header">
        <tr className="table-headings">
          {headers.map((header, index) => (
            <th className="heads" key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data && data.map((row, rowIndex) => (
          <tr className="body-row" key={rowIndex}>
            {keys.map((header, headerIndex) => (
              <>
              {header!='status' && headerIndex!=0 && <td className="body-data" key={`${rowIndex}-${headerIndex}`}>{row[header]}</td>}
              {header!='status' && headerIndex==0 && !navigation && <td className="body-data" key={`${rowIndex}-${headerIndex}`}>{row[header]}</td>}

              {header!='status' && headerIndex==0 && navigation && <td className="body-data" key={`${rowIndex}-${headerIndex}`}><a href={navigation+row.id}>{row[header]}</a></td>}

              {header=='status' && <td className="body-data" key={`${rowIndex}-${headerIndex}`}> <StatusBadge data={row[header]} /></td>}
              </>
            ))}

{isProgressBar && <td>
              {renderProgress && renderProgress((row?.action_items?.completed_action_item_count/row?.action_items?.action_item_count)*100)}
              </td>}
          {isActionButton &&  <td className="body-data">
              {renderButton && renderButton(row)}
            </td>}



          </tr>
        ))}


        {data && data.length ==0 && <td colSpan={5} className="text-center  pt-3">No Data Available</td>}
      </tbody>
    </table>
    </div>
  );
};


