import React, { useState } from 'react';
import logo from '../../../assets/logo.png';
import forgotimg from '../../../assets/forgot-img.png';
import "./ForgotPassword.scss";
import { ForgotPasswordApi } from "../../../services/UserAuthentication.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true); // State to track if the email is valid

    const validateEmail = (email:any) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; // Simple email regex pattern
        return re.test(email);
    };

    const handleEmailChange = (e:any) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsValidEmail(validateEmail(emailValue)); // Validate email on change
    };

    const ForgotPassword = async () => {
        console.log(isValidEmail,"is valid email")
        if (!isValidEmail) {
            toast.error("Please enter a valid email address");
            return;
        }

        let forgot_password_payload = {
            email: email
        }

        let api_response = await ForgotPasswordApi(forgot_password_payload);

        if (api_response) {
            if (api_response.status === 100) {
                toast.success("Check email for the reset password link");
            } else {
                toast.error("Reset password failed");
            }
        }
    };
    return(

        <div className="login-container">
            <div >

            <img src={logo} className="logo-style"/>
            </div>

            <div className="row">

                <div className="col-12 col-md-5 col-lg-5">
                    <p className="forgotpassword-style">Did you forget password?</p>
                    <p className=" forgotpassword-heading2-style">Please enter your contact details to connect.</p>
                <div className="pt-5">
                    <label className="input_label_style">Enter Email</label>
                    <input value={email}   onChange={handleEmailChange}  className=" mt-1 input_style" placeholder="name@taskboard.com"/>
                </div>




                    <div>
                        <button onClick={()=>ForgotPassword()} className="login_btn">Continue</button>
                    </div>
        
                    <p className="bottom-txt">Do you remember your password? <span><a href="/login">Log In here</a></span></p>
                </div>


                <div className="col-12 col-md-7 col-lg-7">
                    <div className="img-container">
                      <div>
                        <p className="text-center forgot-password-txt-style">Forgot Your Password ?</p>
                        <p className="text-center nice-to-see-style">You can change your password</p>
                   <div className="d-flex justify-content-center">
                    <img className="img-fluid" src={forgotimg} />
                    </div>
                    </div>
                    </div>
                </div>

            </div>

        </div>
        );

    }
    
    export default ForgotPassword;