import React, { createContext, useState, ReactNode } from 'react';

// Define the type for your data
type DataType = string | null;

// Create a TypeScript interface for the context value
interface DataContextValue {
  data: DataType;
  setData: (data: DataType) => void;
}

interface DataProviderProps {
    children: ReactNode;
  }
  

// Create the context
const DataContext = createContext<DataContextValue | null>(null);

const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const [data, setData] = useState<DataType>(null);

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
