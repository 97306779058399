import React from 'react';
import './SubmitButton.scss';

type ButtonProps = {
    isDisabled: boolean;
    onClickHandler :()=> void;
    buttonText: string,
    classname: string,
    is_loading: boolean,
};

const SubmitButton = ({ isDisabled, buttonText, onClickHandler, classname, is_loading }: ButtonProps) => {
  return (
    <button
      disabled={isDisabled}
      className={`${classname} ${is_loading ? 'loading' : ''}`}
      onClick={onClickHandler}
    >
      {is_loading ? <div className="loader"></div> : buttonText}
    </button>
  );
};

export default SubmitButton;
