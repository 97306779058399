import { authorizedapiCall } from "../utils/api_util.service";

export const GetTemplateApi = async (params:string="") => {
    try {
      const data = await authorizedapiCall('template/?'+params);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }

  export const GetTemplateByIdApi = async (id:string="") => {
    try {
      const data = await authorizedapiCall('template/'+id+'/');
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }

  export const CreateTemplateApi = async (payload:any) => {
    try {
      const data = await authorizedapiCall('template/','POST',payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }  


  export const UpdateTemplateApi = async (payload:any,id:any) => {
    try {
      const data = await authorizedapiCall('template/'+id+'/','PUT',payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }  


  export const DeleteTemplateApi = async (id:any) => {
    try {
      const data = await authorizedapiCall('template/'+id+'/','DELETE');
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }  

  export const DuplicateTemplateApi = async (id:any) => {
    try {
      const data = await authorizedapiCall('duplicate-template/'+id+'/','PUT');
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }  


  export const VerifyTemplateName = async (name:string="") => {
    try {
      const data = await authorizedapiCall('template-name/?name='+name);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }


  export const VerifyTemplateUpdate = async (id:any) => {
    try {
      const data = await authorizedapiCall('template-edit-verify/'+id+'/');
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }