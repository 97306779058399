import { apiCall } from "../utils/api_util.service";


export const contactUsApi = async (login_payload:any) => {
    try {
      const data = await apiCall('contact-us/','POST',login_payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
}