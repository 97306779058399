import { FileUploadauthorizedapiCall, apiCall, authorizedapiCall } from "../utils/api_util.service";

export const saveFileUpload = async (data_json:any) => {
  console.log("file upload function called")
    try {
      const data = await FileUploadauthorizedapiCall('/source/url/?'+ objectToQueryString(data_json),'GET',data_json.mime_type);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      console.log(error)
      return error;
    }
  };


  function objectToQueryString(obj:any) {
    return Object.keys(obj)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
  }