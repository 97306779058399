import { useEffect, useState } from 'react';
import './TemplateManagement.scss';
import { BsChevronCompactRight } from 'react-icons/bs';
import TemplateBtn from '../../assets/template-btn.png';
import { DeleteTemplateApi, DuplicateTemplateApi, GetTemplateApi } from '../../services/TemplateManagement.service';
import { jsonToUrlParams } from '../../utils/json_to_params_util.service';
import { Table } from '../../components/Table/Table';
import Pagination from '../../components/Pagination/Pagination';
import ReusableModal from '../../components/Modal/Modal';
import CreateTemplateName from './CreateTemplate/CreateTemplateNameModal/CreateTemplateName';
import { useNavigate } from 'react-router-dom';
import NotificationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { Row } from 'react-bootstrap';
import toast from 'react-hot-toast';

const TemplateManagement : React.FC=()=>{
    const [search, setSearchData] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const toggleModal = () => setModalShow(!modalShow);
    const [type, setTyperData] = useState(sessionStorage.getItem('user_type') || localStorage.getItem('user_type'));


    const [deleteShow, setDeleteModalShow] = useState(false);
    const toggleDeleteModal = () => setDeleteModalShow(!deleteShow);


    const [duplicateShow, setDuplicateModalShow] = useState(false);
    const toggleDuplicateModal = () => setDuplicateModalShow(!duplicateShow);


    const navigate = useNavigate()

    var tablePageIndex = 1
    const [currentPage, setCurrentPage] = useState(tablePageIndex);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [dataCount, setDataCount] = useState<number>(0);

    const [templateData, setTemplateData] = useState<any>();
    const headers = ['Template Name','Status','Action'];
    const keys = ['name', 'status'];
    const [templateId, setTemplateId] = useState('');
    const [templateName, setTemplateName] = useState('');

    useEffect(()=>{
        getTemplates()

    },[search])

const getTemplates=async()=>{
    let params = {
        page: tablePageIndex,
        limit: itemsPerPage,
        keyword: search,
    };

    const template_data = await GetTemplateApi(jsonToUrlParams(params));
    let data_array:any=[]
    if(template_data.status==100){
        setDataCount(template_data.count)
        for(let i=0;i<template_data.data.length;i++){
            console.log("vadad")

            let data_json=  {
                "id": template_data.data[i].id,
                "name": template_data.data[i].name,
                "status":template_data.data[i].is_active?"Active":"InActive",
                "created_by_id": template_data.data[i].created_by_id,
                "created_on": "2023-11-10 06:33:06"
            }

            data_array= [...data_array,data_json]

        }

        setTemplateData(data_array)

    }

}



const handleActionClick = (rowData: { [key: string]: any }) => {
    // handle the click event
    // console.log('Button clicked for:', rowData);

    navigate('/view-template/'+rowData.id)
    
  };


  const handleDeleteClick = (rowData: { [key: string]: any }) => {
    // handle the click event
    // console.log('Button clicked for:', rowData);

    setTemplateId(rowData.id)
    toggleDeleteModal()
  
    // navigate('/view-template/'+rowData.id)
    
  };



  const handleDuplicateClick = (rowData: { [key: string]: any }) => {
    // handle the click event
    // console.log('Button clicked for:', rowData);

    setTemplateId(rowData.id)
    setTemplateName(rowData.name)
    toggleDuplicateModal()
  
    // navigate('/view-template/'+rowData.id)
    
  };


  const deleteTemplate=async()=>{
    const delete_response = await DeleteTemplateApi(templateId)
    if(delete_response){

        if(delete_response.status==100){
            getTemplates()
            toast.success("Successfully deleted template")
            setDeleteModalShow(false)
            
        }else{
            toast.error("Error deleting template: "+delete_response.message)
        }
    }
  }



  const duplicateTemplate=async()=>{
    const duplicate_response = await DuplicateTemplateApi(templateId)
    if(duplicate_response){

        if(duplicate_response.status==100){
            getTemplates()
            toast.success("Successfully duplicated template")
            setDuplicateModalShow(false)
            
        }else{
            toast.error("Error duplicating template: "+duplicate_response.message)
        }
    }
  }
const renderActionButton = (row: { [key: string]: any }) => {

    return <div className='d-flex'>
    <button className='view-btn ms-2' onClick={() => handleDuplicateClick(row)}>Duplicate</button>
{type == "SystemAdmin" &&   <button className='view-btn ms-2' onClick={() => handleDeleteClick(row)}>Delete</button>
}    </div>;

};

const handlePageChange = async (pageNumber: any) => {
    await setCurrentPage(pageNumber);
    tablePageIndex = pageNumber
    getTemplates()
  };


return (<div>

      <div className="row">
        <div className="col-12 col-md-8 col-lg-8 d-flex">
          <p className="breadcrumb pe-2">Management</p>
          <BsChevronCompactRight/>
          <p className="breadcrumb ps-2">Template Management</p>
        </div>
</div>


<div className='pb-4 pt-4  d-flex justify-content-between'>
                <div>
                    <input value={search} onChange={(e)=>setSearchData(e.target.value)} className='search-input' placeholder='Search Here' />
                </div>

                <div>
      <button type="button" className="register-modal-btn" onClick={toggleModal}> <span className='pe-2'><img src={TemplateBtn} /></span>
New Template      </button>

    </div>


            </div>



            <Table headers={headers} data={templateData} renderButton={renderActionButton} keys={keys} isActionButton={true} navigation='/view-template/'/>
            <Pagination itemsPerPage={itemsPerPage} count={dataCount} currentPage={currentPage} onPageChange={handlePageChange} />


            <ReusableModal
        show={modalShow}
        toggleModal={toggleModal}
        title="Create User Account"
      >
        <CreateTemplateName />
      </ReusableModal>




      <NotificationModal
        show={deleteShow}
        toggleModal={toggleDeleteModal}
        title="Create User Account"
      >
       <p className='delete-confirmation'> Are you sure you want to delete template ?</p>

        <div className='d-flex justify-content-center'>
        <button onClick={()=>deleteTemplate()} className="delete-btn-confirm me-3 mt-3">Yes</button>
        <button onClick={()=>toggleDeleteModal()} className="no-btn-confirm me-3 mt-3">No</button>
        </div>
      </NotificationModal>



      <NotificationModal
        show={duplicateShow}
        toggleModal={toggleDuplicateModal}
        title="Create User Account"
      >
       <p className='delete-confirmation'> Are you sure you want to duplicate {templateName} template ?</p>

        <div className='d-flex justify-content-center'>
        <button onClick={()=>duplicateTemplate()} className="delete-btn-confirm me-3 mt-3">Yes</button>
        <button onClick={()=>toggleDuplicateModal()} className="no-btn-confirm me-3 mt-3">No</button>
        </div>
      </NotificationModal>



</div>)

}
export default TemplateManagement;
