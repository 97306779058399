import "./StatusBadge.scss";
type InputProps = {
    data: any;
  };
  
  const valueMapping:any = {
    in_progress: 'In Progress',
    open: 'Open',
    complete: 'Complete',
    Active: 'Active',
    InActive: 'In Active' // Assuming 'In Active' is meant to be 'Inactive'
  };
  
  const StatusBadge = ({ data }: InputProps) => {
    const displayValue = valueMapping[data] || data;
  
    return (
      <div><span className={data}>{displayValue}</span></div>
    )
  }
  
  export default StatusBadge;
  