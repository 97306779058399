import { useState } from 'react';
import './DynamicInput.scss';
import FileUpload from '../../../components/FileUpload/FileUpload';

type InputProps = {
    data: any;
    type:any;
    onValueChange:any;
    name:any;
    options:any;
    submit_disabled:boolean;
  };

  interface Gender {
    id: number;
    name: string;
    is_active: number; // or boolean if you convert 0/1 to false/true
    created_by_id: number;
    created_on: string;

  }

  const DynamicInput = ({ data, type, onValueChange, name, options,submit_disabled }:InputProps) => {
    const [trigger, setTrigger] = useState(0);
    const [value, setValue] = useState(data?.value || '');
    console.log("this is fucking data",type)
    const defaultValuesArray = (type == "multiple" && data)?data.value.split(','):[];
    const [selectedGenders, setSelectedGenders] = useState<string[]>(defaultValuesArray);
   
    const handleTextAreaChange = (e:any) => {
        setValue(e.target.value)
    };

    const handleFileChange = (e:any,type:any) => {
      console.log("this is type",type)
        let data_array:any=[]
       
        if(type == "attachment" || type == "files"){
          setValue(e[0])
        }else{
          for(let i=0;i<e.length;i++){
            data={
                "source_url":e[i]
            }
            
            data_array=[...data_array,data]
        }
        console.log("this is e",data_array)
        setValue(data_array)

        }

        // onValueChange(e);
        // ... File upload logic
    };

    const onButtonSubmit=()=>{
      

        console.log("this is vale",value)
        if(type!="checkbox" && type !="multiple"){
            onValueChange(value);
        }else{
            onValueChange(selectedGenders.filter(gender => gender.trim() !== '').join(','))
        }
        
    }

    const handleChange = (event:any) => {
        const { value, checked } = event.target;
        setSelectedGenders((prev:any) => {
          // Add or remove based on the checkbox state
          if (checked) {
            return [...prev, value];
          } else {
            return prev.filter((name:any) => name !== value);
          }
        });
      };

    return (
        <div className="dynamic-input">
            {(type === "textarea" || type === "long") && (
                <>  <p className='dynamic-label'>Add {name}</p>             
                 <textarea placeholder={'Enter '+name} className='txt-area' defaultValue={data.value} onChange={handleTextAreaChange} disabled={submit_disabled}></textarea>
            </>

            )}

{type === "textarea2"  && (
                <>  <p className='dynamic-label'>Add {name}</p>             
                 <textarea placeholder={'Enter '+name} className='txt-area' defaultValue={data} onChange={handleTextAreaChange} disabled={submit_disabled}></textarea>
            </>

            )}

{(type === "string" || type === "short") && (
                <>  <p className='dynamic-label'>Add {name}</p>             
                 <input placeholder={'Enter '+name} className='string' defaultValue={data.value} onChange={handleTextAreaChange} disabled={submit_disabled}/>
            </>

            )}
            {(type === "file" || type === "files" || type === "attachment" )&& (
                <>
          {!submit_disabled &&  <FileUpload
                isUpdate={false}
                trigger={trigger}
                accept="application/pdf, imag/png, image/jpeg, video/*"
                onImagesChange={(e)=>handleFileChange(e,type)}

              />}
              {data && <p className='dynamic-label'>Previous Images</p>}
<div className='d-flex align-items-center'>
  {type !== "attachment" && type !== "files" && data && data.map((value: any) => (
    value.source_url.endsWith(".mp4") ? (
      <video width={200} className='ms-3' controls>
        <source src={"https://taskboard-images.s3.amazonaws.com/" + value.source_url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ) : (
      <img 
        width={75} 
        className='ms-3' 
        src={value.source_url.includes('.pdf')
          ? "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/400px-PDF_file_icon.svg.png"
          : ("https://taskboard-images.s3.amazonaws.com/" + value.source_url)
        } 
        alt={value.source_url.includes('.pdf') ? "PDF Icon" : "Image"}
      />
    )
  ))}
</div>


{type === "attachment" && 
                <img width={75} className='ms-3' src={"https://taskboard-images.s3.amazonaws.com/"+data.value} />
      
              }


{type === "files" && 
                <a href={"https://taskboard-images.s3.amazonaws.com/"+data.value}>{data.value}</a>
      
              }
              </>
              )}



{type === "radio"  && (
                <>  <p className='dynamic-label'>Select {name}</p>           
                {options.map((val:any)=>(
<label className='d-flex dynamic-label'><input name="radio" type='radio' className='m-2' value={val.name} checked={val.name==value ||val.name==data.value } onChange={handleTextAreaChange}/> {val.name}</label>
                ))}  
            </>

            )}


{(type === "checkbox" || type ==="multiple") && (
                <>  <p className='dynamic-label'>Select {name}</p>           
                    {options.map((gender:Gender) => (
          <label className='d-flex dynamic-label' key={gender.id}>

            <input
            className='m-2'
              type="checkbox"
              value={gender.name}
              checked={selectedGenders.includes(gender.name)}
              
              onChange={handleChange}
            />

{gender.name}
          </label>
        ))}
            </>

            )}

            {!submit_disabled &&  <div className='mt-3 d-flex justify-content-end'>
                <button  onClick={()=>onButtonSubmit()} className='dynamic-submit'>Submit</button>
              </div>}
        </div>
    );
};

export default DynamicInput;