import React, { useEffect, useState } from "react";
import './Register.scss';
import { RegisterUserApi } from "../../../services/UserAuthentication.service";
import registerImg from "../../../assets/register-img.png";
import toast from "react-hot-toast";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import { GetAllPropertyApi } from "../../../services/PropertyManagement.service";
import { BsFillDashCircleFill, BsFillPlusCircleFill } from "react-icons/bs";

type RegisterProps = {
    updateData: () => void;
};

const Register = ({ updateData }: RegisterProps) => {
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [isDisabled, setDisabled] = useState(false);
    const [propertyData, setPropertyData] = useState<string[]>(['']);
    const [property, setProperty] = useState([]);
    const [type, setTyperData] = useState(sessionStorage.getItem('user_type') || localStorage.getItem('user_type'));

    useEffect(() => {
        GetProperties();
    }, []);

    const GetProperties = async () => {
        const property_data: any = await GetAllPropertyApi();
        if (property_data.status === 100) {
            setProperty(property_data.data);
        }
    };

    const handlePropertyChange = (index: number, value: string) => {
        const newPropertyData = [...propertyData];
        newPropertyData[index] = value;
        setPropertyData(newPropertyData);
    };

    const addPropertyDropdown = () => {
        const hasEmptyValue = propertyData.some((value) => value === '');
        
        if (hasEmptyValue) {
            toast.error('Property cannot be empty');
        } else {
            setPropertyData([...propertyData, '']);
        }
    };

    const removePropertyDropdown = (index: number) => {
        const newPropertyData = propertyData.filter((_, i) => i !== index);
        setPropertyData(newPropertyData);
    };

    const registerUser = async () => {
        if (!first_name) {
            return toast.error('First name is mandatory');
        }

        if (!last_name) {
            return toast.error('Last name is mandatory');
        }

        if (!role) {
            return toast.error('User role is mandatory');
        }

        if (!email) {
            return toast.error('Email is mandatory');
        }

        if (propertyData.length === 0 || (propertyData.some(p => !p) && role !== "Admin")) {
            return toast.error('Property is mandatory');
        }

        let login_payload = {
            email: email,
            first_name: first_name,
            last_name: last_name,
            user_role: role === "Admin" ? 'admin' : role === "User" ? 'user' : '',
            property_ids: propertyData.filter(p => p) // Remove empty entries
        };

        setDisabled(true);
        let api_response = await RegisterUserApi(login_payload);

        if (api_response) {
            if (api_response.status === 100) {
                updateData();
                setDisabled(false);
                toast.success("Successfully created user");

                setFirstName("");
                setLastName("");
                setEmail("");
                setRole("");
                setPropertyData(['']);
            } else {
                setDisabled(false);
                toast.error("Register User fail: " + api_response.message);
            }
        }
    };

    return (
        <div className="row">
            <div className="col-6 col-md-6 col-lg-6 left-img">
                <img className="img-fluid" src={registerImg} />
            </div>
            <div className="col-12 col-md-12 col-lg-6">
                <p className="register-header">Create User Account</p>
                <p className="register-heading2">Please enter your contact details to create account.</p>

                <div className="pt-3 pb-3">
                    <label className="input_label_style2">First Name</label>
                    <input value={first_name} onChange={(e) => setFirstName(e.target.value)} className=" mt-1 input_style_register" placeholder="First Name" />
                </div>

                <div className="pb-3">
                    <label className="input_label_style2">Last Name</label>
                    <input value={last_name} onChange={(e) => setLastName(e.target.value)} className=" mt-1 input_style_register" placeholder="Last Name" />
                </div>

                <div className="pb-3">
                    <label className="input_label_style2">User Role</label>
                    <select value={role} onChange={(e) => setRole(e.target.value)} className=" mt-1 input_style_register">
                        <option value="">Select Role</option>
                        {type !== "Super" && <option value="User">User</option>}
                        {type == "Super" && <option value="SystemAdmin">System Adminitrator</option>}
                        <option value="Admin">Admin</option>
                    </select>
                </div>

                <div className="pb-3">
                    <label className="input_label_style2">Email Address</label>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} className=" mt-1 input_style_register" placeholder="Email Address" />
                </div>

                {type !== "super" && (
                    <div className="pb-3">
                        <label className="input_label_style2">Select Property</label>
                        {propertyData.map((data, index) => (
                            <div className="row mb-2" key={index}>
                                <div className="col-8">
                                    <select value={data} onChange={(e) => handlePropertyChange(index, e.target.value)} className=" mt-1 input_style_register">
                                        <option value="">Select Property</option>
                                        {property && property.map((value: any) => (
                                            <option key={value.id} value={value.id}>{value.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-2 d-flex align-items-center">
                                    {index === propertyData.length - 1 && (
                                        <div onClick={addPropertyDropdown}>
                                            <BsFillPlusCircleFill style={{ cursor: 'pointer' }} />
                                        </div>
                                    )}
                                </div>
                                <div className="col-2 d-flex align-items-center">
                                    {propertyData.length > 1 && (
                                       <div onClick={()=>removePropertyDropdown(index)}>
                                       <BsFillDashCircleFill  style={{ cursor: 'pointer',color:'red' }} />
                                   </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <div className="d-flex justify-content-end">
                    <SubmitButton
                        isDisabled={isDisabled}
                        buttonText="Save"
                        onClickHandler={registerUser}
                        is_loading={isDisabled}
                        classname="register_btn"
                    />
                </div>
            </div>
        </div>
    );
}

export default Register;
