import { authorizedapiCall } from "../utils/api_util.service";

export const LoggedResetPasswordApi = async (payload:any) => {
    try {
      const data = await authorizedapiCall('reset-password/','POST',payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }