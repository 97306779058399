import { BsChevronCompactRight } from "react-icons/bs"
import "./PasswordManagement.scss";
import { useState } from "react";
import { PasswordInputField } from "../../components/PasswordInput/PasswordInput";
import resetpass from '../../assets/reset-password-img.png';
import SubmitButton from "../../components/SubmitButton/SubmitButton";

import toast from "react-hot-toast";
import { LoggedResetPasswordApi } from "../../services/PasswordManagement.service";

export const PasswordManagement : React.FC=()=>{
    const [current,setCurrent] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isDisabled, setDisabled] = useState(false);

    const handleCurrentPasswordChange = (event:any) => {
      setCurrentPassword(event.target.value);
    };
  
    const handleNewPasswordChange = (event:any) => {
      setNewPassword(event.target.value);
    };
  
    const handleRepeatPasswordChange = (event:any) => {
      setRepeatPassword(event.target.value);
    };
  

    const registerUser=async()=>{
        if(newPassword!==repeatPassword){
            toast.error("The passwords do not match")
        }else{
            let login_payload={
                old_password:currentPassword,
                new_password:newPassword,
            }
            setDisabled(true)
            let api_response = await LoggedResetPasswordApi(login_payload);
    
            if(api_response){
    
                if(api_response.status==100){
               
                    setDisabled(false)
                    toast.success("Successfully the password was reset")
               
                    setNewPassword("");
                    setRepeatPassword("");
                    setCurrentPassword("")
        
    
                }else{
                    setDisabled(false)
                    toast.error("Password Reset fail: "+api_response.message)
        
                }
            }
        }
      

    }
  
    return (
        <div className='main-content  fade-in'>
        <div className="d-flex">
            <p className="breadcrumb pe-2">Management</p>
            <BsChevronCompactRight/>
            <p className="breadcrumb ps-2">User Management</p>
        </div>
        <div className="row">
        <div className="col-12 col-md-6 col-lg-6">

            <p className="pt-4 reset-heading text-center">Reset Password</p>
        <PasswordInputField
          label="Current Password"
          value={currentPassword}
          onChange={handleCurrentPasswordChange}
        />
        <PasswordInputField
          label="New Password"
          value={newPassword}
          onChange={handleNewPasswordChange}
        />
        <PasswordInputField
          label="Repeat Password"
          value={repeatPassword}
          onChange={handleRepeatPasswordChange}
        />
        <SubmitButton isDisabled={isDisabled} onClickHandler={registerUser} buttonText={"Reset Password"} classname={"login_btn"} is_loading={isDisabled} />
        </div>

        <div className="col-12 col-md-6 col-lg-6  d-flex justify-content-center align-items-center">

        <img className="img-fluid" src={resetpass} /> 

        </div>


        </div>
        </div>
    );

}