import React, { useState, useEffect, useRef } from 'react';
import './DynamicDropdown.scss';
import { BsDashCircle } from 'react-icons/bs';

interface dropdownProps{
    onOptionsChange:any;
    values:any;
    isDisabled:boolean;
}

function DynamicDropdownUpdate({ onOptionsChange, values, isDisabled }:dropdownProps) {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<any>();
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef:any = useRef(null);


    useEffect(() => {
        setOptions(values);
        console.log(values)
    }, [values]);

    useEffect(() => {

        console.log(options,"this is option")
        function handleClickOutside(event:any) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    function handleInputChange(e:any) {
        if(isDisabled){
            setInputValue(e.target.value);
            setIsDropdownVisible(true);
        }

    }

    const removeItem = (index:any) => {
          // Update options by removing the item at the specified index
    const updatedOptions = options.filter((_:any, i:any) => i !== index);
    setOptions(updatedOptions);

    // Call the callback with the updated options
    onOptionsChange(updatedOptions);
      };
    

    function handleInputKeyPress(e:any) {
        console.log(isDisabled)
        if (e.key === 'Enter' && inputValue.trim() && isDisabled) {
            const newOptions = [...options, {name:inputValue.trim()}];
            setOptions(newOptions);
            onOptionsChange(newOptions); // Call the callback with the new options
            setInputValue('');
            
        }
    }

    function handleInputClick() {
        setIsDropdownVisible(true);
    }

    return (
        <div className="dynamic-dropdown">
            <input
            className='checklist-input'
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                onClick={handleInputClick}
       
                placeholder={isDisabled?"Type and press Enter":'Click to view options'}
            />
            {isDropdownVisible && (
                <div className="dropdown-list" ref={dropdownRef}>
                    {options.map((option:any, index:number) => (
                       <div className='d-flex justify-content-between'> <div className="dropdown-option" key={index}>{typeof option != 'object'?option:option.name}</div> {isDisabled && <div style={{cursor:"pointer"}} className='d-flex justify-content-center align-items-center pe-2'>{typeof option == 'object' && Object.keys(option).length==1?<span onClick={()=>removeItem(index)}><BsDashCircle /></span>:<></>}</div>} </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default DynamicDropdownUpdate;
