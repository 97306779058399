import viewuserimg from '../../../assets/viewuser-img.png';
import "./ViewUsers.scss";
type LayoutProps = {
    user_data: any;
    openEdit :()=>void;
  };

export const ViewUsers = ({ user_data, openEdit }: LayoutProps) => {

    return(
        <div className="row">
            <div className="col-6 col-md-6 col-lg-6  d-flex justify-content-center align-items-center">
                 <img className="img-fluid" src={viewuserimg} /> 
            </div>
            <div className="col-6 col-md-6 col-lg-6">
                <div className='d-flex justify-content-around'>
                <div>
                    <p className='viewuser-heading'>View User Details</p>
                    <p className='viewuser-heading2'>You can view your details.</p>
                </div>

                <div><button onClick={()=>openEdit()} className='edit-btn'>Edit</button></div> 
                </div>

                <div className='avatar-div d-flex align-items-center'>
                    <div className='avatar-view-user'>W</div>

                    <div className='ps-3'>
                        <p className='user-name pt-3'>{user_data && user_data.name}</p>
                        <p className='user-email pt-2'>{user_data && user_data.email}</p>
                    </div>
                </div>


                <div className='pt-4 user-details'>

                    <div>
                        <p className='attribute-label'>First Name</p>
                        <p className='attribute-value'>{user_data && user_data.first_name}</p>
                    </div>

              

                    <div>
                        <p className='attribute-label'>Last Name</p>
                        <p className='attribute-value'>{user_data && user_data.last_name}</p>
                    </div>

                    <div>
                        <p className='attribute-label'>User Role</p>
                        <p className='attribute-value'>{user_data && user_data.role}</p>
                    </div>      
                    
                     <div>
                        <p className='attribute-label'>Email Address</p>
                        <p className='attribute-value'>{user_data && user_data.email}</p>
                    </div>

                    <div>
                        <p className='attribute-label'>Property</p>
                        <p className='attribute-value'>{user_data && user_data.property}</p>
                    </div>

                </div>


                <div className='d-flex user-details pt-4 pb-4'>
                <div className="form-check form-switch pe-5">
              

                    <input readOnly  checked={user_data && user_data.is_locked} className="form-check-input lock" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                    <label className='lock-txt' >Locked</label>
                    </div>

                    <div className="form-check form-switch">
                <label  className='active-txt'>Active</label>

                    <input  readOnly checked={user_data && user_data.status=="Active"} className="form-check-input active-user" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                    </div>
                </div>

            </div>
        </div>
    );

}