import React, { useState, ChangeEvent, DragEvent, useRef, useEffect } from 'react';
import './FileUpload.scss';
import uploadButton from '../../assets/upload-btn.png';

import pdf from '../../assets/addaction-btn.png'
import toast from "react-hot-toast";
import { saveFileUpload } from '../../services/FileUpload.service';
// import AWS from 'aws-sdk';
interface FileUploadProps {
    accept: string;
    onImagesChange: (base64Images: any) => void;
    update_images?: [],
    trigger?: any;
    isUpdate:boolean;
}


// AWS.config.update({
//   accessKeyId: 'AKIAWLGV3RRDZLETBKXX',
//   secretAccessKey: 'pW+ad2gc0CZRbYu0GV9GJCeuBy59ykyBu5F/zAdT',
//   region: 'ap-southeast-1', // e.g., 'us-east-1'
// })

const FileUpload: React.FC<FileUploadProps> = ({ accept, onImagesChange, update_images, trigger, isUpdate }) => {
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [prevImg, setPrevImg] = useState<string[]>([]);
    const [deletedImages, setdeletedImages] = useState<string[]>([]);
    const [base64Images, setBase64Images] = useState<string[]>(update_images ? update_images : []);
    const [dragging, setDragging] = useState<boolean>(false);
    const [fileNames, setFileNames] = useState<string[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    let tempImages = []

    // let deletedImages: any = [];

    const log = () => {
        setSelectedImages([])
        setBase64Images([])
        setFileNames([])
    };


    useEffect(() => {
       
        console.log("base64Imagesbase64Imagesbase64Images,", update_images)
        if (trigger) {
            log();
        }
    }, [trigger]);
    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files as FileList);
        handleFiles(files);
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(false);
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(false);
        const files = Array.from(e.dataTransfer.files);

        handleFiles(files);
    };

    useEffect(() => {
        // action on update of movies
        if(update_images?.length) {
            console.log(333333333333333333)
            let finalImages: any = [...deletedImages, ...prevImg]
            onImagesChange(finalImages);
        } else {
            onImagesChange(base64Images);
        }
    }, [base64Images]);

    useEffect(() => {
        // action on update of movies
        if(update_images?.length) {
            console.log(2222222222222222222222222222222)
            let finalImages: any = [...deletedImages, ...prevImg]
            onImagesChange(finalImages);
        } else {
            onImagesChange(base64Images);
        }
    }, [prevImg]);

    useEffect(() => {
        console.log(111111111111111111111111111)
        // action on update of movies
        if(update_images?.length) {
            let finalImages: any = [...deletedImages, ...prevImg]
            onImagesChange(finalImages);
        } else {
            onImagesChange(base64Images);
        }
    }, [deletedImages]);

    useEffect(() => {
        // action on update of movies
        setBase64Images(update_images ? update_images : []);
    }, [update_images]);

    const handleFiles = async (files: File[]) => {
        const images: string[] = [];
        const base64Imagess: any[] = [];
    
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = async () => {
                setIsLoading(true);
                const fileUrl = URL.createObjectURL(file);
                images.push(fileUrl);
                setFileNames((prevFileNames) => [...prevFileNames, file.name]);
    
                const image_response: any = await saveUploadFile(file);
                base64Imagess.push(image_response.data["path"]);
    
                if (update_images?.length || isUpdate) {
                    let response: any = {
                        image: image_response.data["path"],
                        temp: fileUrl
                    };
                    let oimages = prevImg;
                    oimages.push(response);
                    await setPrevImg(oimages);
                    let finalImages: any = [...deletedImages, ...prevImg];
                    onImagesChange(finalImages);
                    setIsLoading(false);
                } else {
                    if (base64Imagess.length === files.length && image_response.status === 100) {
                        await setSelectedImages((prevSelectedImages) => [...prevSelectedImages, ...images]);
                        await setBase64Images(prevArray => [...prevArray, ...base64Imagess]);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        toast.error("Upload error: " + image_response.message);
                    }
                }
            };
    
            reader.readAsDataURL(file);
        });
    };
    

    const saveUploadFile = async (base64data: any) => {
        let params: any = {
            // mime_type: 'image/png',
            // extension: 'png',
             mime_type: base64data.type,
            extension: base64data.type.split('/').pop(),
        };
        console.log("Params :", params)
        const create_image = await saveFileUpload(params)
        //console.log(create_image, "create_imagecreate_imagecreate_image")
       
            console.log(base64data.type, "This is file type")
            console.log(create_image, "This is file url")
            console.log("Imgae :", base64data)
            await onFileChange(base64data, create_image["data"]["url"])

        return await create_image
    }

    const onFileChange = async (file: any, presignedUrl: any) => {
        try{

   
      console.log("Imgae :", file)
      console.log("Imgae URL:", presignedUrl)
        // Step 2: Use the presigned URL to upload the file to S3
        await fetch(presignedUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type,
                'x-amz-acl': 'public-read',
            }
        });
    }catch(e){
            console.log(e)
    }
    };


    const inputFileRef: any = useRef(null);

    const openFile = () => {
        inputFileRef?.current?.click();
    };

    const deleteImage = (index: any) => {
        setBase64Images(currentImg => currentImg.filter((img, i) => i !== index));
        setSelectedImages(currentImg => currentImg.filter((img, i) => i !== index));
        setFileNames(currentImg => currentImg.filter((img, i) => i !== index));
    }

    const deleteImageUpdated = (index: any, image: any) => {
        image["is_delete"] = 1

        let temp: any = deletedImages
        temp.push(image)
        setdeletedImages(temp)
        setBase64Images(currentImg => currentImg.filter((img, i) => i !== index));
    }

    const deleteNewAddedImage =  (index: any, image: any) => {
        setPrevImg(currentImg => currentImg.filter((img, i) => i !== index));
    }


    return (
        <div>
            {!false && <div
                className={`image-upload ${dragging ? 'dragging' : ''} text-center`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={openFile}
            >
                <input ref={inputFileRef} disabled={loading} type="file" accept={accept} onChange={handleImageChange} hidden />
               <div>
                <img src={uploadButton} />
                <p className='pt-1 upload_txt'><span><u>Click to upload</u></span> or drag and drop</p>
        
                </div>

            </div>}
            {loading && <p>Please wait.... Image is being uploaded</p>}
            {(selectedImages.length > 0 || update_images) && !loading && (
    <div className='pt-3 pb-3'>
        {!update_images && selectedImages.map((image, index) => (
            <div className='preview_div' key={index}>
                <div>
                    {fileNames[index].endsWith('.pdf') ? (
                        <img width={'35px'} height={'38px'} src={pdf} alt={`PDF ${index}`} />
                    ) : fileNames[index].endsWith('mp4') ? (
                        <video width="75" height="75" controls={false} poster={image}>
                            <source src={image} type={fileNames[index]} />
                        </video>
                    ) : (
                        <img width={'75px'} height={'75px'} src={image} alt={`Selected ${index}`} />
                    )}
                    &nbsp;&nbsp;
                    <span className='file_name'>{fileNames[index]}</span>
                </div>
                <div>
                    <img style={{ cursor: 'pointer' }} onClick={() => deleteImage(index)} className='pe-2' src="" />
                </div>
            </div>
        ))}

        {update_images && base64Images.map((image: any, index) => (
            <div className='preview_div' key={index}>
                <div>
                    {image.expense_image.endsWith('.pdf') ? (
                        <img width={'35px'} height={'38px'} src={pdf} alt={`PDF ${index}`} />
                    ) : image.expense_image.startsWith('video') ? (
                        <video width="75" height="75" controls={false} poster={`https://taskboard-images.s3.amazonaws.com/${image.expense_image}`}>
                            <source src={`https://taskboard-images.s3.amazonaws.com/${image.expense_image}`} />
                        </video>
                    ) : (
                        <img width={'75px'} height={'75px'} src={`https://taskboard-images.s3.amazonaws.com/${image.expense_image}`} alt={`Selected ${index}`} />
                    )}
                    &nbsp;&nbsp;
                    <span className='file_name'>{image.expense_image}</span>
                </div>
                {update_images && (
                    <div>
                        <img style={{ cursor: 'pointer' }} onClick={() => deleteImageUpdated(index, image)} className='pe-2' src="" />
                    </div>
                )}
            </div>
        ))}

        {update_images && prevImg.map((image: any, index) => (
            <div className='preview_div' key={index}>
                <div>
                    {image.image.endsWith('.pdf') ? (
                        <img width={'35px'} height={'38px'} src={pdf} alt={`PDF ${index}`} />
                    ) : image.image.startsWith('video') ? (
                        <video width="75" height="75" controls={false} poster={image.temp}>
                            <source src={image.temp} />
                        </video>
                    ) : (
                        <img width={'75px'} height={'75px'} src={image.temp} alt={`Selected ${index}`} />
                    )}
                    &nbsp;&nbsp;
                    <span className='file_name'>{image.image}</span>
                </div>
                <div>
                    <img style={{ cursor: 'pointer' }} onClick={() => deleteNewAddedImage(index, image)} className='pe-2' src="" />
                </div>
            </div>
        ))}
    </div>
)}



        </div>
    );
};

export default FileUpload;
