import React, { useEffect, useState } from "react";
import logo from '../../../assets/logo.png';
import loginimg from '../../../assets/login-img.png';
import './Login.scss';
import { loginUserApi } from "../../../services/UserAuthentication.service";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import ReusableModal from "../../../components/Modal/Modal";
import { Contact } from "../../Support/Contact/Contact";

const Login : React.FC=()=>{
    const navigate = useNavigate();
    const [email,setEmail] = useState('');
    const [password,setpassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [contactModalShow, setContactModalShow] = useState(false);
    const toggleContactModal = () => setContactModalShow(!contactModalShow);


    const loginUser=async()=>{

        let login_payload={
            email:email,
            password:password
        }

        setDisabled(true)

        let api_response = await loginUserApi(login_payload);

        if(api_response){
            setDisabled(false)
            if(api_response.status==100){
         
                localStorage.setItem("user_name", api_response.data.user.first_name);
                toast.success("Successfully logged in")
                if(rememberMe) {
                    localStorage.setItem("access_token", api_response.data.access_token);
                    
                    if(api_response.data.user.is_super_admin){
                        localStorage.setItem("user_type", 'super');
    
                    }else if (api_response.data.user.is_admin){
                        localStorage.setItem("user_type", 'admin');
                    }else{
                        localStorage.setItem("user_type", 'user'); 
                    }
                } else {
                    sessionStorage.setItem("access_token", api_response.data.access_token);
                    if(api_response.data.user.is_super_admin){
                        sessionStorage.setItem("user_type", 'super');
    
                    }else if(api_response.data.user.is_admin){
                        sessionStorage.setItem("user_type", 'admin');
                    }else{
                        sessionStorage.setItem("user_type", 'user');
                    }
                }



                navigate("/");
            }else{
                toast.error("Login fail: "+api_response.message)
            }
        }

    }

    return(
        <div className="login-container">



            <div className="row">

                <div className="col-12 col-md-5 col-lg-5">
                <div className="d-flex justify-content-between">

<img src={logo} className="logo-style"/>
<div className="d-flex align-items-center">
    <button onClick={()=>toggleContactModal()} className="demo" style={{fontFamily:'Montserrat'}}>Click here for a Demo</button>
</div>
</div>
                    <p className="login-style">Log in</p>
                    <p className=" login-heading2-style">Please enter your contact details to connect.</p>
                <div className="pt-3">
                    <label className="input_label_style">Email address</label>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} className=" mt-1 input_style" placeholder="Email address"/>
                </div>


                <div className="pt-3">
                    <label className="input_label_style">Password</label>
                    <input type="password" value={password} onChange={(e)=>setpassword(e.target.value)} className="mt-1 input_style" placeholder="Password"/>
                </div>


                <div className="pt-3 d-flex justify-content-between">

                    <div>
                    <input checked={rememberMe} 
   onChange={(e) => setRememberMe(e.target.checked)} className="checkbox_style me-2" type="checkbox"></input>
                        <label className="remember_style">Remember me?</label>
                   
                    </div>
                    <p className="remember_style"><a style={{textDecoration:"none"}} href="/forgot-password">Forgot password?</a></p>
                </div>

  
                    <div>
                    <SubmitButton
        isDisabled={(email=="" || password=="")||isDisabled}
        buttonText="Log In"
        onClickHandler={loginUser}
        is_loading={isDisabled}
        classname="login_btn"
      />
                    </div>
        
                    <p className="bottom-txt">Contact admin to create account</p>
                </div>


                <div className="col-12 col-md-7 col-lg-7 welcome-div">
                    <div className="img-container">
                      <div>
                        <p className="text-center welcome-style">Welcome Back</p>
                        <p className="text-center nice-to-see-style">Nice to see you again !</p>
                    <div className="d-flex justify-content-center">
                    <img className="img-fluida" src={loginimg} />
                    </div>
                    </div>
                    </div>
                </div>

            </div>


            <ReusableModal
        show={contactModalShow}
        toggleModal={toggleContactModal}
        title={"Request Demo"}
        is_title={true}
      >
        <Contact closeModal={toggleContactModal}/>
      </ReusableModal>

        </div>
        );

}

export default Login;