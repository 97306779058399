import { utils, writeFile } from 'xlsx';

const exportToExcel = (data:any, fileName:any) => {
  // Create a worksheet from the data
  const worksheet = utils.json_to_sheet(data);

  // Create a new workbook and append the worksheet
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write the workbook to a file
  writeFile(workbook, `${fileName}.xlsx`);
};

export default exportToExcel;
