import React, { useEffect, useState } from "react";
import logo from '../../../assets/logo.png';
import resetimg from '../../../assets/reset-img.png';
import './ResetPassword.scss';
import { ResetPasswordApi } from "../../../services/UserAuthentication.service";
import { useNavigate,useSearchParams  } from "react-router-dom";
import toast from "react-hot-toast";
const ResetPassword : React.FC=()=>{
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [confirm_password,setConfirmPassword] = useState('');
    const [password,setpassword] = useState('');


    const isPasswordValid = (password:any) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/;
        return passwordRegex.test(password);
    }

    const resetpassword=async()=>{

        if(confirm_password!==password){
            toast.error("New password and confirm password does not match");
            return; 
        }

        if (!isPasswordValid(password)) {
            toast.error("Password does not meet the required criteria.");
            return;
        }

        let resetpassword_payload={
            password:password
        }

        let api_response = await ResetPasswordApi(resetpassword_payload,searchParams.get('id'),searchParams.get('token'));

        if(api_response){

            if(api_response.status==100){
                toast.success("Successfully reset password")
                navigate("/");

            }else{
                toast.error("reset password failed")
            }
        }

    }

    return(
        <div className="login-container">
            <div >

            <img src={logo} className="logo-style"/>
            </div>

            <div className="row">

                <div className="col-12 col-md-5 col-lg-5">
                    <p className="reset-style">Reset Password</p>
                    <p className=" reset-heading2-style">Password must contain at least 8-12 characters long, mix of uppercase and lowercase letters, numbers, and special characters</p>
                    <p className="pt-2 reset-heading2-style">Passwords should not include complete dictionary words, names, or easily guessable patterns (like "12345" or "password").</p>
                <div className="pt-4">
                    <label className="input_label_style">New Password</label>
                    <input value={password} onChange={(e)=>setpassword(e.target.value)} type="password"  className=" mt-1 input_style" placeholder=". . . . . . . . . . . . . ."/>
                </div>


                <div className="pt-3">
                    <label className="input_label_style">Confirm Password</label>
                    <input value={confirm_password} onChange={(e)=>setConfirmPassword(e.target.value)} type="password" className="mt-1 input_style" placeholder=". . . . . . . . . . . . . ."/>
                </div>




                    <div>
                        <button onClick={()=>resetpassword()} className="login_btn">Reset Password</button>
                    </div>
        
                   
                </div>


                <div className="col-12 col-md-7 col-lg-7">
                    <div className="img-container">
                      <div>
                        <p className="text-center welcome-style">Do you want to reset <br/> Password ?</p>
                        <p className="text-center nice-to-see-style">You can change your password</p>
                    <div className="d-flex justify-content-center">
                    <img className="img-fluid" src={resetimg} />
                    </div>
                    </div>
                    </div>
                </div>

            </div>

        </div>
        );

}

export default ResetPassword;