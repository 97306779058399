import { CustomAuthorizedApiCall, apiCall, authorizedapiCall } from "../utils/api_util.service";

export const loginUserApi = async (login_payload:any) => {
    try {
      const data = await apiCall('user/login/','POST',login_payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
}


export const ResetPasswordApi = async (login_payload:any,id:any,token:any) => {
    try {
      const data = await CustomAuthorizedApiCall('user/'+id+'/set-password/','POST',login_payload,token);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
}


export const ForgotPasswordApi = async (login_payload:any) => {
    try {
      const data = await apiCall('forget-password/','POST',login_payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
}

export const RegisterUserApi = async (login_payload:any) => {
  try {
    const data = await authorizedapiCall('user/','POST',login_payload);
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
}