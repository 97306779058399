import { CustomAuthorizedApiCall, apiCall, authorizedapiCall } from "../utils/api_util.service";


export const GetUsersApi = async (keyword:any="") => {
    try {
      const data = await authorizedapiCall('user/?'+keyword);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }


  export const UpdateUsersApi = async (id:any,payload:any) => {
    try {
      const data = await authorizedapiCall('user/'+id+'/','PUT',payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }