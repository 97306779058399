import { CustomAuthorizedApiCall, apiCall, authorizedapiCall } from "../utils/api_util.service";

export const GetAllPropertyApi = async (search:any="") => {
    try {
      const data = await authorizedapiCall('property/?'+search);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }


  export const CreatePropertyApi = async (payload:any) => {
    try {
      const data = await authorizedapiCall('property/','POST',payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }

  export const EditPropertyApi = async (id:any,payload:any) => {
    try {
      const data = await authorizedapiCall('property/'+id+'/','PUT',payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }