import MenuBar from '../left-side/Left-side';
import './MainLayout.scss';
import logo2 from '../../assets/logo-inner-lg.png';
import userPic from '../../assets/user-pic.png';
import signout from '../../assets/signout-dropdown.png';
import settings from '../../assets/settings-dropdown.png';
import React, { useState, useEffect, useRef } from 'react';
import { BsList } from 'react-icons/bs';


type LayoutProps = {
    children: React.ReactNode;
  };
  function getFormattedDate() {
    const now = new Date();
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    const dayOfWeek = days[now.getDay()];
    const month = months[now.getMonth()];
    const year = now.getFullYear();
    const date = now.getDate();
    let ordinal;

    if (date % 10 === 1 && date !== 11) {
        ordinal = 'st';
    } else if (date % 10 === 2 && date !== 12) {
        ordinal = 'nd';
    } else if (date % 10 === 3 && date !== 13) {
        ordinal = 'rd';
    } else {
        ordinal = 'th';
    }

    return `${date}${ordinal} ${dayOfWeek}, ${month} ${year}`;
}
export const Layout = ({ children }: LayoutProps) => {
  const [isActive,setIsActive] = useState(false);
  const menuRef:any = useRef(null); // Create a ref for the user menu container

  const [isOpen,setIsOpen] = useState(false);
  const [type,settyperData] = useState(sessionStorage.getItem('user_type')||localStorage.getItem('user_type'));
  
  const logoutFunction=()=>{
    console.log("logout is calling")
    localStorage.clear();
    sessionStorage.clear();
    window.location.href="/login"
  }

  const closeMenu = () => setIsActive(false);


  useEffect(() => {
    const handleClickOutside = (event:any) => {
      // Check if the click is outside the menuRef element
      if (menuRef && menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);
  
  return (
        
      <div className="app-container" >
        <div className='d-flex justify-content-between ps-2 pt-3 pe-lg-5 pe-md-5 pe-0'>
        <div className='d-flex'>
          <div className='menu-icon'><BsList onClick={()=>setIsOpen(!isOpen)} />
          </div>
            <img src={logo2}/>
        </div>

        <div>
          <div className='d-flex'>
          <p className='pe-4 date'>{getFormattedDate()}</p>
        <img style={{cursor:'pointer'}} src={userPic} alt="Avatar" className="avatar" onClick={()=>{setIsActive(!isActive);}}/>








        <div className="user-menu-wrap">

 
  
  <div ref={menuRef} className={`menu-container ${isActive ? 'active' : ''} `}>
    <ul className="user-menu">
      <div className="profile-highlight">
        <img src={userPic} alt="profile-img" width={36} height={36}/>
        <div className="details">
          <div id="profile-name">{localStorage.getItem("user_name") || "Jenny"}</div>
        </div>
      </div>
     
  
      <div className="footer">
        <li className="user-menu__item" onClick={()=>logoutFunction()}><a className="user-menu-link" ><span className='me-3 span-border'><div><img src={signout}/></div></span>Sign Out</a></li>
        <li className="user-menu__item"><a className="user-menu-link" href="/settings"><span className='me-3 span-border'><div><img src={settings}/></div></span>Settings</a></li>
      </div>
    </ul>
  </div>
</div>










    
        </div>
        </div>
        </div>

        <div style={{display:"flex"}}>
       <MenuBar type={type} isOpen={isOpen}/>
        <div className="main-content pe-lg-5 pe-md-5 p-3 p-lg-0 p-md-0 fade-in">
          {children} {/* This will be replaced by the current route's component */}
        </div>
      </div>
      </div>
    );
  };
  