

import React, { useEffect, useState } from "react";
import { BsChevronCompactRight, BsX, BsXLg } from "react-icons/bs";
import "./Dashboard.scss";
import { Table } from "../../components/Table/Table";
import checklistbtn from "../../assets/checklist-btn.png"
import applybtn from "../../assets/apply-btn.png"
import { useNavigate } from "react-router-dom";
import { jsonToUrlParams } from "../../utils/json_to_params_util.service";
import { GetChecklistApi } from "../../services/ChecklistManagement.service";
import Pagination from "../../components/Pagination/Pagination";
import DynamicDropdown from "../../components/DynamicDropdown/DynamicDropdown";

import resetbtn from "../../assets/load.png";

const Dashboard : React.FC=()=>{
  const navigate = useNavigate();
  const favourites = ['Name', 'Email', 'Status', 'Role', 'Action'];

  var tablePageIndex = 1
  const [currentPage, setCurrentPage] = useState(tablePageIndex);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [dataCount, setDataCount] = useState<number>(0);
  const [checklistData, setChecklistData] = useState<any>();
 
  const headers = ['Check List Name','Status', 'Due Date', 'Progress Bar', 'Action'];
  const tableData = [{Name:"CLN0001",assign_to:"Admin",status:"Complete",template:"Custom",Properties:"A",age:"10"}]
  const keys = ['name', 'status', 'due_date' ];

  const [searchName, setSearchName] = useState('');
  const [searchStatus, setSearchStatus] = useState('');

  const [isReset, SetisReset] = useState(false);

  useEffect(() => {
    GetChecklists();
}, []);

useEffect(() => {
  GetChecklists();
}, [isReset]);


const SearchUsingFilters=()=>{
  GetChecklists()
}
const GetChecklists= async()=>{
  let params = {
    page: tablePageIndex,
    limit: itemsPerPage,
    status: searchStatus,
    keyword: searchName
};
    const checkist_data = await GetChecklistApi(jsonToUrlParams(params));
    console.log(checkist_data)
    let data_array:any=[]
    if(checkist_data.status==100){
      setDataCount(checkist_data.count)
        // for(let i=0;i<users_data.data.length;i++){
      
        //     let data_json={
        //         id:users_data.data[i].id,
        //         name:users_data.data[i].name,
        //         assignee:users_data.data[i].assignee_name,
        //         status:users_data.data[i].status,
        //         template_name:users_data.data[i].template_name,
        //         property_name:users_data.data[i].property_name,
        //         property:users_data.data[i].property_name,
        //         property_id:users_data.data[i].property_id,
        //         is_locked:users_data.data[i].is_locked,
        //         is_verified:users_data.data[i].is_verified,
        //     }
        //     data_array= [data_json,...data_array]

        // }
        console.log("vadad",data_array)
         setChecklistData(checkist_data.data)
        
    }
    
}

  const handleActionClick = (rowData: { [key: string]: any }) => {
    // handle the click event
    console.log('Button clicked for:', rowData);
    navigate('checklist/'+rowData.id)
  };

  const handleActionClick2 = (rowData: { [key: string]: any }) => {
    // handle the click event
    console.log('Button clicked for:', rowData);
    navigate('checklist-management/view/'+rowData.id)
  };

  const handlePageChange = async (pageNumber: any) => {
    await setCurrentPage(pageNumber);
    tablePageIndex = pageNumber
    GetChecklists()
  };

const renderActionButton = (row: { [key: string]: any }) => {

    return <div className="d-flex justify-content-around"><button className='view-btn' onClick={() => handleActionClick2(row)}>Print</button></div>;

};

const renderActionButton2 = (row: { [key: string]: any }) => {

  return <button className='view-btn' onClick={() => handleActionClick2(row)}>Print</button>;

};

const renderProgressBar=(width:any)=>{

  return <div className="progress" style={{height: "10px"}}>
  <div className="progress-bar" role="progressbar" style={{width: width+'%'}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
</div>
}


const resetBtn=()=>{
  setSearchName('')
  setSearchStatus('')
  SetisReset(!isReset)
}
    return(
      <div className='main-content  fade-in'>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <p className="breadcrumb pe-2">Home</p>
          
        </div>

  
</div>




      <div className="filter-box mt-3">
        <div className="d-flex justify-content-between pb-2">
          <div className="row w-100">
            {/* {favourites.map((value)=>(
   
              <div className="col-2 me-lg-0 me-md-0 me-5"><span className="recently-viewed">{value} <BsX className="recent-cross" /></span></div>
            ))} */}

          </div>

          <div>
          <div className="d-flex">
      <button onClick={()=>SearchUsingFilters()} type="button" className="apply-btn me-2" > <span className='pe-2'><img src={applybtn} /></span>
Apply     </button>
<span onClick={()=>resetBtn()} style={{cursor:'pointer'}}><img width={35} className="img-fluid" src={resetbtn}/></span>

        </div>
            </div>    
        </div>


        <div className="row filter-margin">
          <div className="col-6 col-md-3 col-lg-3">
              <label className="filter-label">Name</label>
              {/* <select className=" mt-1 filter-select">
                <option>Check List Name</option>
              </select> */}
              <input value={searchName} onChange={(e)=>setSearchName(e.target.value)} className="mt-1 filter-select" placeholder="Enter name" />
          </div>



          <div className="col-6 col-md-3 col-lg-3">
              <label  className="filter-label">Status</label>
              <select value={searchStatus} onChange={(e)=>setSearchStatus(e.target.value)} className=" mt-1 filter-select">
              <option value="">Select status</option>
                <option value="open">Open</option>
                <option value="complete">Complete</option>
                <option value="in_progress">In Progress</option>
                <option value="draft">Draft</option>
     
              </select>
          </div>


        </div>
      </div>

<div className="mt-3">
<Table headers={headers} data={checklistData} renderButton={renderActionButton} keys={keys} isActionButton={true} isProgressBar={true} renderProgress={renderProgressBar} navigation="/checklist/"/>
      
      <Pagination itemsPerPage={itemsPerPage} count={dataCount} currentPage={currentPage} onPageChange={handlePageChange} />
      </div>



        </div>
        );

}

export default Dashboard;

