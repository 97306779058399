
import { useState } from 'react';
import templateImg from '../../../../assets/templateCreate.png';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import './CreateTemplateName.scss';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { VerifyTemplateName } from '../../../../services/TemplateManagement.service';
interface templateNameProps{

}

const CreateTemplateName=({}:templateNameProps)=>{
    const navigate = useNavigate()
    const [name, setName] = useState('');
    const [isDisabled, setDisabled] = useState(false);

    const createTemplateName=async()=>{

        if(!name){
            return toast.error("You cannot leave template name blank");
            
        }

        const verify = await VerifyTemplateName(name)

        if(verify.status==100){
            navigate('/create-template/'+name)
        }else{
            return toast.error(verify.message);
        }

        

    }


    return(
        <div className="row">
        <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center ">
             <img className="img-fluid" src={templateImg} /> 
        </div>

        <div className="col-12 col-md-6 col-lg-6">

        <div className='pb-3'>
                    <p className='viewuser-heading'>Create New Template</p>
                    <p className='viewuser-heading2'>Please enter your template name.</p>
                </div>


                <div  className="pb-4">
    <label className="input_label_style2">Template Name</label>
    <input value={name} onChange={(e)=>setName(e.target.value)} className=" mt-1 input_style_register" placeholder="Enter the Template Name"/>
</div>


<ul className='pt-5 pb-3'>
    <li>Build out reusable templates and save it. You can add your new task by using it.</li>
    <li>You can also reuse the templates by duplicating them.</li>
</ul>



<div className="pt-3 d-flex justify-content-end">
   
    <SubmitButton
        isDisabled={isDisabled}
        buttonText="Save"
        onClickHandler={createTemplateName}
        is_loading={isDisabled}
        classname="propertysave-btn"
      />
</div>


        </div>

        </div>
    )

}
export default CreateTemplateName