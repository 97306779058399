import React, { useState, useEffect, useRef } from 'react';
import './DynamicDropdown.scss';
import { BsDashCircle } from 'react-icons/bs';

interface dropdownProps{
    onOptionsChange:any;
}

function DynamicDropdown({ onOptionsChange }:dropdownProps) {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<any>([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef:any = useRef(null);

    useEffect(() => {
        function handleClickOutside(event:any) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    function handleInputChange(e:any) {
        setInputValue(e.target.value);
        setIsDropdownVisible(true);
    }

    const removeItem = (index:any) => {
          // Update options by removing the item at the specified index
    const updatedOptions = options.filter((_:any, i:any) => i !== index);
    setOptions(updatedOptions);

    // Call the callback with the updated options
    onOptionsChange(updatedOptions);
      };
    

    function handleInputKeyPress(e:any) {
        if (e.key === 'Enter' && inputValue.trim()) {
            const newOptions = [...options, inputValue.trim()];
            setOptions(newOptions);
            onOptionsChange(newOptions); // Call the callback with the new options
            setInputValue('');
            
        }
    }

    function handleInputClick() {
        setIsDropdownVisible(true);
    }

    return (
        <div className="dynamic-dropdown">
            <input
            className='checklist-input'
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                onClick={handleInputClick}
                placeholder="Type and press Enter"
            />
            {isDropdownVisible && (
                <div className="dropdown-list" ref={dropdownRef}>
                    {options.map((option:any, index:number) => (
                       <div className='d-flex justify-content-between'> <div className="dropdown-option" key={index}>{option}</div> <div style={{cursor:"pointer"}} className='d-flex justify-content-center align-items-center pe-2'><span onClick={()=>removeItem(index)}><BsDashCircle /></span></div> </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default DynamicDropdown;
